import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal,Box, Button, Stack , Input, Typography, TextField } from '@mui/material'
import React, { useState } from 'react'
import CloseButton from '../../../components/CloseButton'



const LockoutModal = (props) => {
    const { setLockoutModal, lockoutModal, lock } = props
    const [ email, setEmail ] = useState('')
    const [ actionError, setActionError ] = useState('')
   
  return (
    <>
        <Modal 
            // open={LockoutModal}
            open={lock}
            onClose={()=>setLockoutModal(!lockoutModal)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'#000',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem'
                    
                    }}>
                        {/* <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setLockoutModal} val={LockoutModal}
                        
                            />
                        </Box> */}
                    <Box component={'img'}
                     className='lock-logo'
                    src={`/assets/Rex_Entropy_Icon_Glow.png`}
                    sx={{width:'100%', height:'auto'}}
                    >
                    </Box>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        position:'absolute',
                        borderRadius:'10px',
                        border:'2px solid #000',
                        backgroundColor:'rgba( 255,255,255,0.3)',
                        backdropFilter:'blur(3px)',
                        boxShadow:'0px 0px 20px 4px rgba( 255,255,255,0.2)',
                        width:'300px',
                        height:'200px',
                        left:'50%', top:'60%', 
                        transform:'translate(-50%,0%)'}}>
                        <Stack sx={{ gap:'0.5rem', width:'100%',justifyContent:'center',
                            alignItems:'center'
                        }}>
                            <h3>please enter your email to continue</h3>
                            <TextField sx={{wdith:'90%'}} onChange={(e)=>{setEmail(e.target.value)}}/>
                            <Button variant='outlined' onClick={()=>{
                                if(email){
                                    window.location.assign(`/music/${email}`)
                                }else{setActionError('Email required')}

                            }}>
                                submit
                            </Button>
                            
                        </Stack>
                    </Box>
                      
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default LockoutModal