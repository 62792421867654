import React , { useState, useContext, useRef ,useEffect} from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import {  List, Stack,ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { StorefrontContext, }  from "../../context/StorefrontProvider";
import useWindowDimensions from "../../hooks/windowDimensions";
import AddToCartButton from './AddToCartButton';


const ProductCard = (props) => {
    const card_size = props.size
    const { modalOpen, setModalOpen,cart,
        setCart,order, view, setView, subView , 
        setSubView, currentProduct, setCurrentProduct } = useContext(StorefrontContext);
    const collections = props.collections
    const header_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
    const item = props.item
    const { height, width } = useWindowDimensions();
    const [ showAddToCart, setShowAddToCart ] = useState(false)

    const ref = useRef(null)

    const handleViewSelect = (e) =>{
        setView(e.target.value)
    }
    useEffect(() => {
        ref.current.addEventListener('onMouseEnter',()=>{
            setShowAddToCart(true)
            
        })
        ref.current.addEventListener('onMouseLeave',()=>{
            setShowAddToCart(false)
            
        })
    }, [ref])
    
  return (
     <Box  sx={{ position:'relative',minWidth:'200px', width:'50%',maxWidth:'300px',fontFamily:'Gilroy, sans-serif', 
        padding:'0.5rem',
        // margin:'auto',display:'flex',
        // flexDirection:'column',justifyContent:'center',
        }}>
            <Box ref={ref} onMouseEnter={()=>{setShowAddToCart(true)}} onMouseLeave={()=>{setShowAddToCart(false)}}
                sx={{positon:'relative',width: width >= 750 ? '100%':'300px',overflow:'hidden', margin:'auto',
                    display:'flex',flexDirection:'column', justifyContent:'center',
                }}>
                <img src={item?.images[0]} alt={`${item?.name} product image`} style={{width:width >= 750 ? '100%':'200px'}}/>
                <Box sx={{width:'90%', display: 'flex',position:'relative', height: '3rem',  alignItems:'center',
                justifyContent:'center',
                    // border:'1px solid red'
                }}>
                {showAddToCart && 
                        <AddToCartButton item={item} func={setModalOpen} sx={{ margin:'auto' }} view='product-card'/>
                    }
                    </Box>
                </Box>

                <Box sx={{display:'flex', flexDirection:'column',justifyContent:'left', textAlign:'left'}}>

                    <Typography variant='h4' sx={{marginBottom:'0px',fontFamily:'Glass Antiqua'}}>
                     {item?.name}
                    </Typography>

                    <Typography variant='p' sx={{marginTop:'0px'}}>
                    { item?.subtitle ?` ( ${item?.subtitle} )`:''}
                    </Typography>

                     <Typography variant='h6' sx={{position:'relative'}}>
                       ${item?.price}.<span style={{fontSize:"0.75rem",position:'absolute',top:'0.2rem'}}>00</span>
                    </Typography>
                     <Button sx={{borderColor: '#757575', color:'#000'}}
                     
                      onClick={()=>{
                        setCurrentProduct(item)
                        setView('product')
                        }}
                        variant='outlined'>View Product</Button>
                     
                </Box>
   </Box>
  )
}

export default ProductCard