import { Box, Button, Card, Container, Paper, 
  Typography,Modal, Stack, Divider,IconButton, Tooltip } from '@mui/material'
import React,{useEffect, useContext, useState} from 'react'
import { AppContext } from "../context/AppProvider";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { QRCodeContext } from "../context/QRCodeProvider"
import QRCodeStyling from "qr-code-styling";

import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from "../hooks/windowDimensions";

import * as API from '../utils/APICalls'
import * as Order from '../utils/OrderLogic'
import * as helpers from '../utils/helpers'
import QRFraming from './QRFraming';
import CreateHMNIStickerAsset from './CreateHMNIStickerAsset';
import CompileComboPrintfile from '../views/storefrontComponents/CompileComboPrintfile';

const defaultQR = require('../defaults/BaseShopQR.json')



const ReviewOrder = (props) => {
//    const order = props.order

    const { appData,userData, setUserData, setPersonas, currentAsset,
      currentPersona,updateAppData,modalOpen ,editUser, setEditUser,scans,
      currentCart,subView, setSubView,
      currentOrder, setCurrentOrder, currentShop, orders, setOrders,
      personas, assets, setAssets,selectedQR,appTheme, setAppTheme,actionText, setActionText} = useContext(AppContext);
    const { qrCodeData, updateQRCodeData, setQRCodeData } = useContext(QRCodeContext);

      const { height, width } = useWindowDimensions();
    let status_color = helpers.orderColorCheck(currentOrder?.status)
    const [ showDetails ,setShowDetails ] = useState(false)
    const [ showActions ,setShowActions ] = useState(true)
    const [ loading, setLoading ] = useState(false)
    // const  [ subView, setSubView] = useState('review')
    const subViews = ['review','actions']
    const  [ actionView, setActionView] = useState('')
    const  [ processView, setProcessView] = useState('QR')

   const orderActions = ['Process','Send','Cancel','Contact Customer']
   const processActions = ['QR','Asset','Printfile']
    
   const printTypes =[ 'nametag mod','nametag plain','porthole','base' ]
    const [ actionModalOpen, setActionModalOpen ] = useState(false)
    const [ framingModalOpen, setFramingModalOpen ] = useState(false)
     
    let qrCodeRaw 

    const shirtTypeFilter = (name) =>{
      if(name.includes('[ Base ]')){
        return 'base'
      }
      else if(name.includes('Hello My Name is')){
        return 'nametag plain'
      }
      else if(name.includes('Porthole To My Heart')){
        return 'porthole'
      }
      else{
        return 'nametag mod'
      }
    }

    const orderType = shirtTypeFilter(currentOrder?.itemName)
    console.log('[OrderType:', orderType)

    const fetchOrders = async (shop_id) =>{
        try{ 
          const data = await API.fetchOrders(shop_id)
            if(data){
          
              console.log('orders:', data)
               setOrders(data)
        
              return data
            }
          }catch(err){console.log(err)}
    }
    
    const handleQRDelete = async () =>{
      try{

        const res = API.deleteQR(currentOrder?.qr_id)
        // updateQRCodeData(defaultQR)
        // if(res === 'success'){
            const orderRes = await API.updateOrder({...currentOrder,
              qr_id:'',
              qr_pin: '',
              qr_pic: '',
              qr_pic_public_id: ''
            })
    
            if(orderRes){
              setCurrentOrder(orderRes)
              updateAppData({
                ...appData,
                currentOrder
              })
            // }
  }
      }catch(err){console.log('[QR Delete Error]',err)}
      
    }
    const handleQRImageDelete = async () =>{
      try{

        const res = API.deleteQRImage(currentOrder?.qr_pic_public_id)
        // updateQRCodeData(defaultQR)
        // if(res === 'success'){
            const orderRes = await API.updateOrder({...currentOrder,
              qr_pic: '',
              qr_pic_public_id: ''
            })
    
            if(orderRes){
              setCurrentOrder(orderRes)
              updateAppData({
                ...appData,
                currentOrder
              })
            // }
  }
      }catch(err){console.log('[QR Delete Error]',err)}
      
    }

    const saveBaseQRImage = async (qrData = qrCodeData) => {
      try{
        
        qrCodeRaw = new QRCodeStyling(qrData).getRawData('png')
        console.log('[saveBaseQRImage] qrCodeRaw:',qrCodeRaw)
            var blob = new Blob([qrCodeRaw], { type: "image/png" });
        
            // const qrCodeURL = await URL.createObjectURL(blob);
            // await saveBaseQRImage(qrCodeURL)

            console.log('[saveBaseQRImage] blob:',blob)

           const data = new FormData();
             data.append("file", blob);
             data.append("public_id",`QauRa_FramedQR-${qrData?._id}`);
            //  data.append("use_filename",true);
             data.append("upload_preset", "TinCan");
             data.append("folder", "QRS");
             data.append("cloud_name", "dzxzdsnha");
            //  data.append("return_delete_token", 1);

             fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
               method: "post",
               body: data,
               return_delete_token: 1
             })
               .then((res) => res.json())
               .then(async(data) => {
                console.log('[pic response]:', data)
                //  setSavedQRLink(data?.url?.toString());
                 console.log('[saveBaseQRImage] data?.url?.toString():',data?.url?.toString())
                  
                 try{
                  const res = await API.updateQRCode({
                      ...qrCodeData,
                      pic_url: data?.url?.toString(),
                      pic_public_id:  data?.public_id
                  })
                  
                  if(res){
                    updateQRCodeData(res)
                      const orderRes = await API.updateOrder({
                       ...currentOrder,
                       qr_id: res?._id,
                       qr_pin: res?.pin,
                       qr_pic: data?.url?.toString(),
                       qr_pic_public_id: res?.pic_public_id
                   })
             
                   if(orderRes){
                     setCurrentOrder(orderRes)
                     fetchOrders(orderRes?._id)
                     updateAppData({
                       ...appData,
                       currentOrder
                     })
                    }else{console.log('[saveBaseQRImage] -- [No QR Code Response]')}
                  
                    return res
                  }else{console.log('[saveBaseQRImage] -- [No Order Response]')}
                
                  // console.log('[BaseQRImage]:',data.url);
              }catch(err){console.log('[QR Generation error]',err)}

                //  setPicLoading(false);
               })
               .catch((err) => {
                 console.log('!!!****QR Build failed****!!!',err);
                //  setPicLoading(false);
               });
              //  link.click();
            // }
          }catch(err){console.log('[saveBaseQRImage]-- catch :',err)}
  };

    const handleQRCreate = async (e) =>{
      e.preventDefault()
      try{
          setLoading(true);
          const hexString = await helpers.generateRandomHex(6);

          if(currentOrder){
              const data = await API.createQR({
                ...defaultQR,
                  owner: currentShop?.owner?._id, 
                  name: currentOrder?.itemName +' Asset - ', 
                //   data: `https://qaura.pro/assets/${qrCodeData?._id}`,
                  pin: hexString,
                })
                console.log('[handleQRCreate] data:', data)
                if(data?.owner){
                  updateQRCodeData(data)
                  try{
                    console.log('[handleQRCreate] data:', data)

                    const baseQR = await saveBaseQRImage(data)
                    console.log('[handleQRCreate] data:--->',data)
                    console.log('[handleQRCreate] baseQR:--->',baseQR)
                    if(baseQR?.pic_url){
 
                      console.log('[handleQRCreate] baseQR:--->',baseQR)
                    }else{
                      console.log('[handleQRCreate] baseQR data not recieved')
                    }

                    // const qrCodeRaw = await new QRCodeStyling(data).getRawData('png')
                    // var blob = new Blob([qrCodeRaw], { type: "image/png" });
                
                    // const qrCodeURL = await URL.createObjectURL(blob);
                  // if (baseQR) {

                  //   const orderRes = await API.updateOrder({
                  //      ...currentOrder,
                  //      qr_id: qrCodeData?._id,
                  //      qr_pin: qrCodeData?.pin,
                  //      qr_pic: qrCodeData?.qr_pic,
                  //      qr_pic_public_id:  qrCodeData?.pic_public_id
                  //  })
             
                  //  if(orderRes){
                  //    setCurrentOrder(orderRes)
                  //    fetchOrders(orderRes?._id)
                  //    updateAppData({
                  //      ...appData,
                  //      currentOrder
                  //    })
                  //  }
                  // }
                    
                    }catch(err){console.log('[handleQRCreate] Error:',err)}
                    console.log('[handleQRCreate] [currentOrder]:',currentOrder)
                  }
                }
                setLoading(false);
      }catch(err){console.log('[saveBaseQRImage]----[Error]',err)}
    }

    const handleQRPicCreate = () =>{
      if(qrCodeData){
        setFramingModalOpen(true)

      }
    }
    const handleAssetDelete = () =>{
//       if(currentOrder){
//         setFramingModalOpen(true)
// e
//       }
    }
    const handleAssetCreate = () =>{
      if(currentOrder){
        setFramingModalOpen(true)

      }
    }
    const handleFileDelete = () =>{
      // if(currentOrder){
      //   setFramingModalOpen(true)

      // }
    }
    const handleFileCreate = () =>{
      if(currentOrder){
        setFramingModalOpen(true)

      }
    }

    
    useEffect(()=>{

    },[currentOrder,qrCodeData,orders, currentOrder?.qr_pic, currentOrder?.printFile])
    
  return (
    <Container sx={{display:'flex',flexDirection:'column',gap:'0.5rem',maxHeight:'90vh'}}>
         <ArrowBackIcon style={{fontSize:'2rem',//position:'absolute',
            left:'2rem', cursor: 'pointer', opacity: '0.5',zIndex:9999,
            top: '0rem', marginTop:'0.5rem'}}
            onClick={()=>{
              // if( currentCart?._id ){
                setSubView('review-cart')
                
              // }else{
                
                updateAppData({
                  ...appData,
                  modalView:'storefront'
                })
              }
            }
            
            />
            <Modal 
            open={framingModalOpen} 
            onClose={()=>setFramingModalOpen(false)}
            >
              <Container 
              className='center'
              sx={{p:1, 
                 margin:'auto',
                fontFamily:'Gilroy, sans-serif',
              width:'80%', height:'60%',
              }}>
               <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center', position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height:'fit-content',
                    minHeight:'50vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                  maxHeight:'70vh',
                    // maxHeight:'90vh',
                    maxWidth:'60vw',
                    margin:'auto',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon onClick={()=>setFramingModalOpen(false)}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
             <h1>{actionView} Framing</h1>
            
             {processView === 'QR' && <QRFraming view='process' setFramingModalOpen={setFramingModalOpen} handleQRImageDelete={handleQRImageDelete}/>}
             {processView === 'Asset' && <CreateHMNIStickerAsset view='build-shirt' qr_pic={currentOrder?.qr_pic } setFramingModalOpen={setFramingModalOpen}/>}
             {processView === 'Printfile' && <CompileComboPrintfile view='process' qr_pic={currentOrder?.qr_pic } 
                type={orderType}
                setFramingModalOpen={setFramingModalOpen}/>}
             
             </Box>
             </Container>
             </Modal>


            <Modal 
            open={actionModalOpen} 
            onClose={()=>setActionModalOpen(false)}
            >
              <Container 
              className='center'
              sx={{p:1, 
                fontFamily:'Gilroy, sans-serif'
              // width:'80%', height:'60%',
              }}>
               <Box sx={{ position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height:'90%',
                    minHeight:'70vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                  maxHeight:'90vh',
                    // maxHeight:'90vh',
                    maxWidth:'98vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon onClick={()=>setActionModalOpen(false)}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
             <h1>{actionView}</h1>
             <Divider />
                <Stack sx={{position:'relative',padding:'0.5rem'}}>
                  {
                    actionView === 'Process' &&<Box sx={{display:'flex', flexDirection:'column',}}>
                    
                      <Box sx={{display:'flex', flexDirection:'row',padding:'0.5rem', gap:'0.25rem',justifyContent:'space-evenly',height:'5rem'}}>
                        {processActions.map((action,i)=>{
                          if(
                            ( action === 'QR' && currentOrder?.qr_id !== 'n/a' )
                          || ( action === 'Asset' && currentOrder?.asset_url !== 'n/a' )
                          || ( action === 'Printfile' && currentOrder?.printFile !== 'n/a' )
                          ){

                            return(
  
                              <Button 
                                variant={action === processView?'outlined':''}
                                key={i} onClick={(e)=>{
                                
                                setProcessView(action)
                       
                              }} > 
                                    {action}
                              </Button>
                            )
                          }
                        })}
                      
                      </Box>
                        {processView === 'QR'&&<Box sx={{display:'flex',flexDirection:'column',gap:'0.5rem'}}>
                       <Divider />
                        <h2>
                            {processView} Processing
                          </h2> 
                        <span sx={{margin:'auto',alignSelf:'center'}}><b>id: </b>{currentOrder?.qr_id }  <Tooltip title='copy link'>

                            <IconButton onClick={(e)=>{
                                navigator.clipboard
                                  .writeText(currentOrder?.qr_id )
                                  .then(() => {
                                      alert(`${currentOrder?.qr_id } successfully copied`);
                                  })
                                  .catch(() => {
                                      alert("something went wrong");
                                  });
                            }}>
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip></span>
                       
                       <Divider />
                       
                        {/* {currentOrder?.qr_id !== 'n/a' && currentOrder?.qr_id ? <span ><b>id: </b>{currentOrder?.qr_id }</span>:''} */}
                        {/* {currentOrder?.qr_pic !== 'n/a' && currentOrder?.qr_pic ? <span><b>pic: </b>{currentOrder?.qr_pic }</span>:''} */}
                        {/* {currentOrder?.qr_pic !== 'n/a' && currentOrder?.qr_pic ? <span><b>pic: </b>{currentOrder?.qr_pic }</span>:''} */}
                     <Box sx={{display:'flex', flexDirection: width<=400?'column':'row'}}>
                      {(currentOrder?.qr_pic !== 'n/a'  && currentOrder?.qr_pic )&& 
                        
                        <Box sx={{position:'relative',border:'1px solid #757575',borderRadius:'5px',width:'50%', margin:'auto'}}>
                          <img src={currentOrder?.qr_pic}  width='100%'/>
                          {/* <Typography sx={{position:'absolute',left:'50%',top:'50%', transform:'translate(-50%,-50%)'}}> no QR image</Typography> */}
                        </Box>
                        }

                        <Stack sx={{gap:'0.5rem', padding:'0.5rem', justifyContent:'center', margin:'auto'}}>
                          <Button variant='outlined'onClick={()=>{handleQRDelete()}} >
                            Delete QR
                          </Button>
                          <Button variant='outlined'onClick={(e)=>{handleQRCreate(e)}} >
                            New QR
                          </Button>
                          <Button variant='outlined'onClick={()=>{handleQRImageDelete()}} >
                            Delete QR Image
                          </Button>
                          <Button variant='outlined' onClick={()=>{handleQRPicCreate()}}>
                            New QR Image
                          </Button>
                        </Stack>
                      </Box>
                        {currentOrder?.qr_id !== 'n/a' && !currentOrder?.qr_id ? 'missing QR' :''}
                        {currentOrder?.qr_id !== 'n/a' && currentOrder?.qr_id && !currentOrder?.qr_pic  ? 'missing QR pic' :''}
                        {currentOrder?.qr_id === 'n/a' ? 'no QR required' :''}
                         
                        </Box>}
                      
                     {processView === 'Asset' &&<Box sx={{display:'flex',flexDirection:'column',gap:'0.5rem'}}>
                       <Divider />
                        <h2>
                            {processView} Processing
                          </h2> 
                        {/* <span sx={{margin:'auto',alignSelf:'center'}}><b>id: </b>{currentOrder?.qr_id }</span> */}
                         {currentOrder?.asset_text && currentOrder?.asset_text?.map((text,i)=>{
                        return(
                          <span>
                            <b>line {i+1} text: </b>{text}
                          </span>
                        )
                      })}
                       <Divider />
                       
                        {/* {currentOrder?.qr_id !== 'n/a' && currentOrder?.qr_id ? <span ><b>id: </b>{currentOrder?.qr_id }</span>:''} */}
                        {/* {currentOrder?.qr_pic !== 'n/a' && currentOrder?.qr_pic ? <span><b>pic: </b>{currentOrder?.qr_pic }</span>:''} */}
                        {/* {currentOrder?.qr_pic !== 'n/a' && currentOrder?.qr_pic ? <span><b>pic: </b>{currentOrder?.qr_pic }</span>:''} */}
                    
                     <Box sx={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                    
                     <Box sx={{display:'flex', flexDirection: width<=400?'column':'row'}}>
                      {(currentOrder?.qr_pic !== 'n/a'  && currentOrder?.qr_pic )&& 
                        
                        <Box sx={{position:'relative',border:'1px solid #757575',borderRadius:'5px',width:'50%', margin:'auto'}}>
                          <img src={currentOrder?.asset_url}  width='100%'/>
                          {/* <Typography sx={{position:'absolute',left:'50%',top:'50%', transform:'translate(-50%,-50%)'}}> no QR image</Typography> */}
                        </Box>
                        }

                        <Stack sx={{gap:'0.5rem', padding:'0.5rem', justifyContent:'center', margin:'auto'}}>
                          <Button variant='outlined' onClick={()=>{handleAssetDelete()}}>
                           Delete Asset
                          </Button>
                          <Button variant='outlined' onClick={()=>{handleAssetCreate()}}>
                            Generate Asset
                          </Button>
                        </Stack>
                      </Box>
                      </Box>
                        {currentOrder?.asset_url !== 'n/a' && !currentOrder?.asset_url ? 'missing asset' :''}
                      
                        {currentOrder?.asset_url === 'n/a' ? 'no Asset required' :''}

                        </Box>}
                     {processView === 'Printfile' &&<Box sx={{display:'flex',flexDirection:'column',gap:'0.5rem'}}>
                       <Divider />
                        <h2>
                            {processView} Processing
                          </h2> 
                        {/* <span sx={{margin:'auto',alignSelf:'center'}}><b>id: </b>{currentOrder?.qr_id }</span> */}
                         {/* {currentOrder?.asset_text && currentOrder?.asset_text?.map((text,i)=>{
                        return(
                          <span>
                            <b>line {i+1} text: </b>{text}
                          </span>
                        )
                      })} */}
                       <Divider />
                       
                        {/* {currentOrder?.qr_id !== 'n/a' && currentOrder?.qr_id ? <span ><b>id: </b>{currentOrder?.qr_id }</span>:''} */}
                        {/* {currentOrder?.qr_pic !== 'n/a' && currentOrder?.qr_pic ? <span><b>pic: </b>{currentOrder?.qr_pic }</span>:''} */}
                        {/* {currentOrder?.qr_pic !== 'n/a' && currentOrder?.qr_pic ? <span><b>pic: </b>{currentOrder?.qr_pic }</span>:''} */}
                    
                     <Box sx={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
                    
                     <Box sx={{display:'flex', flexDirection: width<=400?'column':'row'}}>
                      {(currentOrder?.qr_pic !== 'n/a'  && currentOrder?.qr_pic )&& 
                        
                        <Box sx={{position:'relative',border:'1px solid #757575',borderRadius:'5px',width:'50%', margin:'auto'}}>
                          <img src={currentOrder?.printFile}  width='100%'/>
                          {/* <Typography sx={{position:'absolute',left:'50%',top:'50%', transform:'translate(-50%,-50%)'}}> no QR image</Typography> */}
                        </Box>
                        }

                        <Stack sx={{gap:'0.5rem', padding:'0.5rem', justifyContent:'center', margin:'auto'}}>
                          <Button variant='outlined' >
                           Delete File
                          </Button>
                          <Button variant='outlined' onClick={()=>{handleFileCreate()}}>
                            Generate File
                          </Button>
                        </Stack>
                      </Box>
                      </Box>
                        {currentOrder?.printFile !== 'n/a' && !currentOrder?.printFile ? 'missing printFile' :''}
                        {/* {currentOrder?.qr_id !== 'n/a' && currentOrder?.qr_id && !currentOrder?.qr_pic  ? 'missing QR pic' :''} */}
                        {currentOrder?.printFile === 'n/a' ? 'no QR required' :''}

                        </Box>}
                    </Box>
                  }



                </Stack>



             </Box>
              </Container>
            </Modal>
        <h3 style={{display:'flex', gap:'0.5rem'}}>
            Review Order
             <span style={{color: status_color,backgroundColor:'rgba(0,0,0,0.2)', border:'1px solid #757575',
             display:'flex', flexDirection:'column',
         borderRadius:'5px',padding:'0.125rem 0.25rem'}}>{currentOrder?.status}</span>
        </h3>
         <Container sx={{display:'flex',flexDirection:'column',gap:'0.5rem',padding:'0.5rem',
          maxHeight:'90vh',overflowX:'hidden', overflowY:'scroll'}}>
          <div style={{cursor:'pointer'}}onClick={()=>{setShowActions(!showActions)}}>Order Actions  <img className={`${showActions?'rot180':'rot-180'}` }
            src={`/assets/arrow-${showActions?'up':'down'}.svg`} style={{height:'0.5rem', alignSelf: 'center',opacity:showActions?'1':'0.3'}} alt="Clear Current Asset"/></div>
              {showActions && <Box sx={{display:'flex', flexDirection:'row',padding:'0.5rem', gap:'0.25rem',justifyContent:'center',height:'5rem'}}>
                    {orderActions.map((action,i)=>{
                      return(

                        <Button key={i} onClick={(e)=>{
                          
                          setActionView(action)
                          setActionModalOpen(true)
                          
                        }} > 
                              {action}
                        </Button>
                      )
                    })}
                  
                  </Box>}

          <div style={{cursor:'pointer'}} onClick={()=>{setShowDetails(!showDetails)}}>Order Details  <img className={`${showDetails?'rot180':'rot-180'}` }
            src={`/assets/arrow-${showDetails?'up':'down'}.svg`} style={{height:'0.5rem', alignSelf: 'center',opacity:showDetails?'1':'0.3'}} alt="Clear Current Asset"/></div>
              {showDetails &&<Box sx={{display:'flex', flexDirection:'column',padding:'0.5rem', gap:'0.25rem'}}>
                      {Object.keys(currentOrder)?.map((el,i)=>{
                          if(typeof currentOrder[el] === 'string'  || typeof currentOrder[el] === 'number' || typeof currentOrder[el] === 'boolean'){
                              return <span key={i}><b>{el}:&nbsp;</b><span>{currentOrder[el.toString()]}</span></span>
                          }
                          if(typeof currentOrder[el] === 'array' ){
                              <div ><b>{el}:&nbsp;</b>
                              {currentOrder[el].map((item,j)=>{
                                  return <span key={j}>{item}</span>
                              })}
                              </div>

                          }
                      })}
                  </Box>}
              {/* <img src={currentOrder?.printFile} width={'100%'} height='auto' alt='order printfile'/> */}
      
        {/* {subView ==='review-order' &&  */}
        <Paper sx={{display:'flex',flexDirection:'column'}}>
              <img src={currentOrder?.type === 'music'? currentOrder?.proof :currentOrder?.printFile} width={'100%'} height='auto' alt='order printfile'/>
          </Paper>
          {/* } */}
        {subView ==='actions' && <Paper sx={{display:'flex',flexDirection:'column'}}>

      
          </Paper>}
      </Container>  
    </Container>

  )
}

export default ReviewOrder