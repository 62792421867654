import { Box, Card, Container, List, ListItem, Paper, Stack, Typography } from '@mui/material';
import React,{useContext,useEffect} from 'react'
import { AppContext } from "../context/AppProvider";

const ProductList = (props) => {
    const items = props.items
   
    const { appData,userData, setUserData, updateAppData, currentShop,
        currentProduct, setCurrentProduct } = useContext(AppContext);
    
    useEffect(()=>{

    },[currentShop,items])
    return (
    <Container sx={{position:'relative',margin:'auto',padding:'1rem 0.5rem',}}>

     {/* <Paper sx={{position:'relative', overflow:'hidden',overflowY:'scroll'}}> */}
     <List sx={{position:'relative',display:'flex',gap:'1rem',alignItems:'center',
        flex:1, minHeight:'500px',height:'40vh',
        justifyContent:'space-evenly',padding:'1rem 0.5rem',overflowX:'hidden',overflowY:'scroll',
        textAlign:'left',flexWrap:'wrap',margin:'auto'}}>

        { items ? items.map((item,j)=>{
            // console.log('items list',item)
            return (   

                <ListItem key={'item-'+j} style={{cursor:'pointer',height:'1.5rem', 
                    minWidth: '150px',
                    maxWidth: '250px',
                    width:'25%',
                    height:'200px',
                    // padding:'0.5rem',
                    // flexWrap:'wrap',
                    justifyContent:'center'}} 
                    onClick={()=>{
                        setCurrentProduct(item)
                 
                        updateAppData({
                            ...appData,
                            currentProduct,
                            modalView:'edit-product'
                        })
                    }}
                    >
                     <Card  style={{cursor:'pointer',height:'1.5rem', 
                    minWidth: '150px',
                    maxWidth: '250px',
                    width:'100%',
                    height:'100%',
                    padding:'0.5rem',justifyContent:'center'}} >
                        <Stack sx={{gap:'0.5rem'}}>
                        <Box sx={{display:'flex',position:'relative', top: '0.2rem', right:'0.2rem',fontSize:'0.75rem',gap:'0.25rem',alignItems:'center',}}>
                            <div style={{content:'',width:'0.75rem',height:'0.75rem', borderRadius:'50%',backgroundColor: item?.isActive ? '#00FF00':'#ffe485', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.3)'}}></div>
                            {item?.isActive ? 'active':'inactive'}
                        </Box>
                            <img src ={item?.images[0]} width='auto' height='80px'/>
                    <b>{item?.name}</b>
                   <Typography> <b>sales: </b> {item?.total_sales ? item?.total_sales: 0}</Typography>
                        </Stack>
                        </Card>

                </ListItem>
                )
        }):'no products available'}
    </List>
    {/* </Paper> */}
    </Container>
  )
}

export default ProductList