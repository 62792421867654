import { Box, Container, Divider, Typography } from '@mui/material'
import React from 'react'

const CompleteOrder = () => {
  return (
    <Container sx={{position:'relative',display:'flex', flexDirection:'column',textAlign:'center', 
        margin:'2rem auto', padding:'1rem',gap:'0.5rem', fontFamily:'Gilroy, sans-serif',
        border: '2px solid #757575', borderRadius:'5px', minHeight:'200px',width:'100%'
    }}>
        <u>

        <Typography variant='h2'sx={{fontWeight:'bold'}}>Order Complete!</Typography>
        </u>
        <Typography variant='p'sx={{fontSize:'1.5rem'}}>Check your email for your confimation, and order processing details.</Typography>
        <Divider />
        <Typography variant='h4'sx={{fontWeight:'bold'}}>Thank you for your Support!</Typography>
    </Container>
  )
}

export default CompleteOrder