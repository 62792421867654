import React , { useState, useContext } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import {  List, Stack,ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { StorefrontContext, }  from "../context/StorefrontProvider";

const AboutView = (props) => {
    const { modalOpen, setModalOpen,cart,
        setCart,order, view, setView,subView , setSubView} = useContext(StorefrontContext);
    const collections = props.collections
    const header_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
    
    const handleViewSelect = (e) =>{
        setView(e.target.value)
    }
  return (
     <Box  sx={{minWidth:'250px',fontFamily:'Gilroy, sans-serif', padding:'0.5rem'}}>
                          {/* <Typography  variant='h3' sx={{textAlign:'left'}}>Menu</Typography>   */}
                          <Typography variant='h3'>
                            About 
                            </Typography> 
                           <Box sx={{margin:'auto', alignItems:'center', display:'flex',width:'100%'}}>
                                <img src={header_pic} width='100px'style={{opacity:'0.65', alignSelf:'center',margin:'auto'}} />
                           </Box>
                         <Divider />
                         <Box sx={{display:'flex',flexDirection:'column',minHeight:'25vh', justifyContent:'center'}}>
                            <Typography>
                                About text about the brand and such.
                            </Typography>
                         </Box>
                       
                         </Box>
  )
}

export default AboutView