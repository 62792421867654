import { Box, Button, List, ListItem, Paper, Stack, Typography } from '@mui/material'
import React, {useState ,useEffect, useContext} from 'react'
import useWindowDimensions from "../../hooks/windowDimensions";


import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { position } from '@chakra-ui/react';
import AddToCartButton from './AddToCartButton';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Products = (props) => {
   const { modalOpen, setModalOpen,cart,setCart,order,
    currentProduct , setCurrentProduct, setView, view,
    } = useContext(StorefrontContext);

const { productsHeight } = props.conf
const  items  = props.items
// const  setModalOpen = props.setModalOpen
const  cartOpen = props.cartOpen
const [ showDetails, setShowDetails ] = useState(false);
const [ currentIndex, setCurrentIndex ] = useState(0);
const { height, width } = useWindowDimensions();

 const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

  useEffect(()=>{

  },[showDetails])
  return (
    <Box sx={{
        positon:'relative',
        width: '100%', 
        maxWidth:  width >= 750 ? '800px':'375px',
        height: productsHeight,
        border: '1px solid #000',
        // borderRadius: '10px',
        borderLeft: 'none',
        borderRight: 'none',
        display:'flex', 
        flexDirection: width >= 750?'row':'column',
        // flexDirection:'column',
        gap:'0.5rem',
        margin: 'auto',
        padding:'0.5rem',
        // alignItems:'center'
        }}>
       <h2>Featured Products</h2>
        <Swiper
        //  className='swiper-container'
            sx={{margin:'auto', maxWidth: width >= 750 ?'800px':'375px'}}
            cssMode={true}
            spaceBetween={200}
            slidesPerView={1}
            // slidesPerColumn={1}
            breakpoints={{
                    // when window width is >= 640px
                    0: {
                    width: 350,
                    // margin:'auto',
                    slidesPerView: 1,
                    spaceBetween:200
                    },
                    // when window width is >= 640px
                    // 640: {
                    // width: 640,
                    // slidesPerView: 1,
                    // },
                    // when window width is >= 768px
                    750: {
                    width: 750,
                    slidesPerView: 1,
                    },
                }}
            onSlideChange={handleSlideChange}
            speed={1000}
            // autoplay={true}
            loop={true}
            // modules={[Autoplay]}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            navigation={true}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)} // Optional: access Swiper instance
        >
            {items?.map((item, index) => (
            <SwiperSlide key={index} sx={{margin:'auto', width: width >= 750 ?'80%':'320px'}}>
                <Box sx={{
                        positon:'relative',
                        height:width >= 750 ? '350px':'100%', 
                        margin:'auto',
                        // maxWidth: width >= 750 ? '800px' :'500px',
                        width: width >= 750 ?'100%':'350px',overflowY:'scroll',
                        display:'flex', flexDirection: width >= 750?'row':'column', 
                        alignItems:'center',
                        // border:'1px solid red',
                        padding:'0.5rem',
                        gap:'0.75rem', justifyContent:'center', textAlign:'center'}}>
                    
                    <Box sx={{positon:'relative',width: width >= 750 ? '50%':'250px',overflow:'hidden'}}>
                    <img src={item?.images[0]} alt={`${item?.name} product image`} style={{width:width >= 750 ? '100%':'100%'}}/>
                    </Box>


                    <Stack sx={{
                        positon:'relative',
                        width: width >= 750 ? '40%':'100%',
                        maxWidth:'250px',
                        // fontSize:width >= 750?'inherit':'0.75rem',
                        justifyContent:'center', textAlign:'left', gap:'0rem'}}> 

                    <Typography variant='h4' sx={{marginBottom:'0px',fontFamily:'Glass Antiqua'}}>
                        {item?.name}
                    </Typography>

                    <Typography variant='p' sx={{marginTop:'0px'}}>
                     { item?.subtitle ?` ( ${item?.subtitle} )`:''}
                    </Typography>

                    <Typography variant='h6' sx={{position:'relative'}}>
                       ${item?.price}.<span style={{fontSize:"0.75rem",position:'absolute',top:'0.2rem'}}>00</span>
                    </Typography>
                    
                    {item?.description && <Typography sx={{maxWidth:'350px', wordWrap:'break-word', wordBreak:'break-word'}}>
                        {item?.description}

                    </Typography>}
                    {( showDetails ) && <u style={{cursor:'pointer'}} onClick={()=>setShowDetails(!showDetails)}>less...</u>}
                    {( showDetails) ? <List  >
                        {item?.details?.map((d, index) => {
                        return <ListItem>{d}</ListItem>
                    })}
                    </List> 
                    : <u style={{cursor:'pointer', marginBottom:'0.75rem',zIndex:999}} onClick={()=>setShowDetails(!showDetails)}>more...</u>}

                    <Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem', alignSelf: width >= 750 ? 'left': 'center'}}>
                        <Button variant='outlined' sx={{borderColor: '#757575', color:'#000'}}
                        onClick={()=>{
                            setCurrentProduct(item)
                            setView('product')
                        }}
                        
                        >View Product</Button>
                      <AddToCartButton item={item} func={setModalOpen}  view='products'/>
                    </Stack>

                    </Stack>
                </Box>
            </SwiperSlide>
            ))}
        </Swiper>
    </Box>
  )
}

export default Products