import React , { useState, useContext } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import {  List, Stack,ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { StorefrontContext, }  from "../../context/StorefrontProvider";

const MenuContent = (props) => {
    const { modalOpen, setModalOpen,cart,
        setCart,order, view, setView,subView , setSubView} = useContext(StorefrontContext);
    const collections = props.collections
    // const  props.func = props.func()
    const header_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
    
    const collectionTypes =  [...new Set(collections?.map((item) => item?.type))]
    const handleViewSelect = (e) =>{
        setView(e.target.value)
    }
  return (
     <Box  sx={{minWidth:'250px',fontFamily:'Gilroy, sans-serif', padding:'0.5rem'}}>
        {/* <Typography  variant='h3' sx={{textAlign:'left'}}>Menu</Typography>   */}
        <Box 
        onClick={()=>{setView('home')}}
        sx={{margin:'auto', alignItems:'center', display:'flex',width:'100%',cursor:'pointer',}}>
            <img src={header_pic} width='100px'style={{opacity:'0.65', alignSelf:'center',margin:'auto'}} />
        </Box>
        <Divider />
        <Box  sx={{padding:'0.5rem'}}>
        <List >
                    <Typography variant='h6'
                    onClick={(e)=>{
                        setView('home')
                        console.log('[View]',view)
                        props.func(false)
                    }}
                        sx={{cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
                    >
                        Home
                    </Typography>
            <Typography variant='h6'>
                Collections
            </Typography>
            {collectionTypes?.sort()?.map((type,j)=>{

                return(
                    <>
                    <Typography>{type[0].toUpperCase() + type.substring(1)}</Typography>
                    { collections?.map((ele,i)=>{
                        if(ele?.type === type){
                            
                            return <ListItem key={i} onClick={(e)=>{
                                try{
                                    console.log('[subview ]', ele?.name?.toString())
                                    setSubView(ele?.name?.toString())
                                    setView('collection')
                                    console.log('[subview]', subView)
                                    props.func(false)
                                }catch(err){console.log(err)}
                            }}
                            sx={{cursor:'pointer',textTransform:'capitalize',
                                "&:hover": {borderBottom:'1px solid #757575'}}}
                            >{ele?.name}</ListItem>
                        }else{
                            return
                        }
                    })}
                    </>
                )
            })
            }
        </List>
            <Typography variant='h6'
            onClick={(e)=>{
                setView('about')
                console.log('[View]',view)
                 props.func(false)
            }}
                sx={{cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
            >
                About The Brand
            </Typography>
            <Typography variant='h6'
            onClick={(e)=>{
                setView('how it works')
                console.log('[View]',view)
                 props.func(false)
            }}
                sx={{cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
            >
                How It works
            </Typography>
            <Typography variant='h6'
            onClick={(e)=>{
                setView('contact')
                console.log('[View]',view)
                 props.func(false)
            }}
                sx={{cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
            >
                Contact
            </Typography>
        </Box>
    </Box>
  )
}

export default MenuContent