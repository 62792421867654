import { Box, List, ListItem } from '@mui/material'
import React ,{useEffect} from 'react'
import PlaylistCard from './PlaylistCard'


const Playlist = (props) => {
    const {album, setAudioObj, audioObj,playAudio, playAlbum, setPlayAlbum,
            setPlayAudio, toggleAudio,colors, setTrkNum, albums,listEmail,
            duration, listener, setListener,
            trkNum,albumNav, setAlbumNav ,saveListen}= props
//             useEffect (()=>{

//     console.log('playAudio:', playAudio)
//   },[playAlbum, playAudio])
  return (
    <List sx={{overflow:'scroll'}}>
        {album && album?.tracks?.map((item,i)=>{
            return(
                // <ListItem key={'playlistCard' + i}>
                    <PlaylistCard item={item}  key={'playlistCard' + i} index={i}
                        setAudioObj={setAudioObj}
                        saveListen={saveListen}
                        duration={duration}
                        albums={albums}
                        audioObj={audioObj}
                        setPlayAudio={setPlayAudio}
                        playAudio={playAudio}
                        setPlayAlbum={setPlayAlbum}
                        playAlbum={playAlbum}
                        toggleAudio={toggleAudio}
                        setTrkNum={setTrkNum}
                        trkNum={trkNum}
                        albumNav={albumNav}
                        listEmail={listEmail}
                        setAlbumNav={setAlbumNav}
                        colors={colors}
                         listener={ listener}
                          setListener={ setListener}
                        
                        style={{
                        padding:'0.5rem',
                    
                        
                    }}
                    onClick={(e)=>{

                        setAudioObj({
                            ...item,
                            id: i
                        })
                        // console.log('current audio:',audioObj)
                    }}
                    />
                // </ListItem>
            )
        })
            
        }
        <Box sx={{height:'10rem'}}>

          </Box>
    </List>
  )
}

export default Playlist