import React,{ useState, useEffect, useContext } from 'react'

import { Box, Button, TextField, Typography } from '@mui/material';

import Login from './auth/Login'
import Signup from './auth/Signup'
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'
import { Link } from 'react-router-dom';
import ResetPasswordComponent from './ResetPasswordComponent';
// import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';


const Auth = () => {
    const [toggleSignup, setToggleSignup ] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const { userData,setUserData,appData, updateAppData,modalOpen ,
        currentPersona, setCurrentPersona,oauthUser, setOauthUser, actionError,setActionError} = useContext(AppContext);
    const guestUser ={
        // _id: '64ad7b1f2beaf8f749a86beb',
        // pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1686761978/Rex_Entropy_Icon_Glow3_mxyw45.png',
        email: 'guest@rexentropy.com',
        password: 'guest',
        // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0YWQ3YjFmMmJlYWY4Zjc0OWE4NmJlYiIsImlhdCI6MTY4OTgzNTk0NiwiZXhwIjoxNjkyNDI3OTQ2fQ.q5X47Ggmq9a4kHxGJuwTeY0MCSfS2mE60rjOA4vJGiQ'
    }

    const guestLogin = async ()=>{
        const data = await API.userLogin({guestUser});
         setUserData(data)
        updateAppData({...appData,userData})

        
        console.log('[guestLogin]: ',userData)
        // setUserData({...data})
        localStorage.setItem('QauRaUser', JSON.stringify(data))
        setUserData({...JSON.parse(localStorage.getItem('QauRaUser'))})
        // console.log('[guestLogin] userData: ', userData)
    }
    // const login = useGoogleLogin({
    //     onSuccess: (codeResponse) => setOauthUser(codeResponse),
    //     onError: (error) => console.log('Login Failed:', error)
        
    // });
    // const errorMessage = (error) => {
    //     console.log(error);
    // };

  return (
    <>
    {/* <div 
    className='color-grad-blue-border'
    style={{display:'flex',flexDirection:'column', margin: 'auto', 
        justifyContent:'center', alignItems:'center', width: '100%', border: '1px solid #fff', boxShadow: 'inset 0px 0px 10px 5px rgba(0,0,0,0.5)'}}> */}
    <div  className='color-grad-blue-border'
        style={{position:'relative',display:'flex',flexDirection:'column', margin: 'auto', justifyContent:'center', 
            alignItems:'center', overflow: 'hidden', overflowY: 'scroll', padding: '1rem',minWidth:'350px',height: 'fit-content',
            width: '100%', maxHeight: '80vh',borderRadius: '0px 0px 10px 10px',
             // border: '1px solid #fff', boxShadow: 'inset 0px 0px 10px 5px rgba(0,0,0,0.7)'
            }}>

        <div className='color-grad-blue-border' 
            style={{border: '3px solid #fff', position:'relative',
                borderRadius: '10px', width: '60%', minWidth: '285px', margin: '0.75rem auto',
            
                boxShadow:'0px 0px 15px 1px rgba(250,250,250,0.5)',
                
                backgroundColor:'rgba(250,250,250,1)',backdropFilter: 'blur(5px)', 
                padding:'1rem',
                overflow: 'hidden',
                overflowY: 'scroll'
        
        }} >
            <div style={{display:'flex', flexDirection:'column',gap:'0.5rem'}}>

               {!forgotPassword && <div className="mx-auto"style={{display:'flex',flexDirection:'row',position:'relative', top:'0',justifyContent:'center', alignItems:'center', width: '100%',height:'50%',margin:'0.75rem auto'}}>
                    <Button title='login' onClick={()=>setToggleSignup(false)} variant={!toggleSignup?"contained":''}><b>login</b></Button>
                    <Button title='signup' onClick={()=>setToggleSignup(true)} variant={toggleSignup?"contained":''}><b>sign up</b></Button>
                </div>}
            
            {/* <button className="redirect-button"
                style={{margin:'0.75rem auto'}}
            onClick={() =>{
                guestLogin()
                // window.location.assign(`/dashboard/00000001`)
                window.location.assign(`/dashboard/${userData?._id}`)
            }}
            >
                Test User
            </button> */}
            </div>
            <div style={{height:'100%', maxHeight:'100%'}}>
           {!forgotPassword && <>
                {!toggleSignup && <Login/>}
                {toggleSignup && <Signup/>}
            </>
            }
            {forgotPassword &&
                <Box sx={{position:'relative',display:'flex',
                     flexDirection:'column', gap:'0.5rem', justifyContent:'center', alignItems:'center'}}>
                    <ResetPasswordComponent view='login'/>
                    <Button sx={{position:'absolute', bottom:'-4rem', right:'0.5rem'}} onClick={()=>{setForgotPassword(!forgotPassword)}}>
                        Cancel
                    </Button>
                </Box>
                
                }

               {!forgotPassword && <Link  sx={{margin:'auto',border:'1px solid red',}}onClick={()=>{setActionError('');setForgotPassword(!forgotPassword)}}>forgot your password?</Link>}
            </div>
       
    <br />
    <div style={{display: 'flex', justifyContent:'center',width:'60%',margin: 'auto'}}>

            {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
            
            {/* <GoogleLogin onClick={()=>{
                login()
                console.log("Google OauthUser: ", oauthUser)
            }} /> */}
        </div>
        <br />
        <br />
    </div>  
    
     </div> 
    {/* </div> */}
    
</>
  )

}

export default Auth