import React ,{ useEffect, useRef, useState, useContext } from "react";
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import { AppBar, Button,Grid, TextField, Box, Container, Stack, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import InquirySendModal from './InquirySendModal';
import ConfirmSignupModal from "../../components/ConfirmSignupModal";
import { AppContext } from "../../context/AppProvider";

import * as API from '../../utils/APICalls'

const CustomerBilling = (props) => {
    // type BasicCardInfo = {
    //   cardNumber: string;
    //   cardCode: string;
    //   month: string;
    //   year: string;
    // };
    const [value, setValue] = useState(0);
    const { owner, operating_name, owner_email, campaingName } = props.shop
    // const subView = props.subView 
    const [ inquiry, setInquiry ] = useState({})
    const [cardData, setCardData] = useState({
        name:'',
        cardNumber: '',
        month: '',
        year: '',
        cardCode: '',
    });
    const { appData,updateAppData, previewQR, setPreviewQR, 
        setSelectedQR, selectedQR, } = useContext(AppContext);
    const {
         signupData, 
        setStorefrontData,
        storefrontData,
        updateStorefrontData, 
        cart,setCart,
        isFollower, tempOrders,
        subView, setSubView ,
        billingInfo, setBillingInfo,
        setIsFollower,order} = useContext(StorefrontContext);

    const [ actionError, setActionError ] = useState('')
    const [ isSubmitting , setIsSubmitting ] = useState(false)
    const [ open, setOpen ] = useState(false);


    const handleSubmit =async (event) => {
    event.preventDefault();
    setIsSubmitting(true)

    const c = billingInfo
        console.log('Shipping info:', c)
        if( !c?.name || !c?.cardNumber || !c?.month || !c?.year || !c?.cardCode){
                alert('Please complete or re-enter your payment information')
            setIsSubmitting(false)
            }
        else{

         try{
                const res = await API.updateCart({
                    ...cart,
                    billing_info: billingInfo
                    // {
                    //     name: cardData?.name,
                    //     email: cardData?.email,
                    //     phone: cardData?.phone
                    // }
                })
                if (res?._id){
                    
                    setCart({
                        ...res,
                    })
                    
                    updateStorefrontData({ 
                        ...storefrontData,
                        cart
                    })
                    setSubView('review')
                    updateAppData({
                        ...appData,
                        subView
                    })
                    // console.log('Submit tempOrder',  tempOrder);
                    console.log('storefrontData', storefrontData);
                }
                setOpen(!open)
                setIsSubmitting(false)
        }catch(err){
            setIsSubmitting(false)
            setActionError('Order failed to submit')
            console.log(err)  
        }
    }
  };

  const handleBack = () =>{
   setSubView('shipping')
  }
      const handleInquiryChange = (e) =>{
        setActionError('')
    //    setInquiryText(e.target.value)
        // console.log('inquiry:', inquiry)

    }
   

    useEffect(()=>{
        // setCart(props.cart)
    },[])

  return (
    <Container  sx={{p:1}}>

        <Box component="form" //noValidate 
                // onSubmit={orderView? handleSubmit : handleInquirySubmit } 
                sx={{ mt: 3,p:3,border:'1px solid #000',borderRadius:'10px' }}>
                    <Grid container spacing={2}>
               {/* {subView === 'inquiry' && 
                     <Grid item xs={24} sm={12}>
                <TextField
                        placeholder="What do you have in mind?"
                        name='inquiry'
                        // value={inquiryText}
                        onChange={(e)=>handleInquiryChange(e)}
                        multiline
                        rows={6}
                        // maxRows={10}
                        sx={{width:'100%'}}
                        />
                    </Grid>} */}
                <Grid item xs={12} sm={6}>
                    <TextField
                    autoComplete="full-name"
                    name="fullName"
                    required
                    fullWidth
                    id="fullName"
                    label="Name on Card"
                    autoFocus
                     defaultValue={ billingInfo['name']?billingInfo['name']:''}
                    onChange={(e)=>setBillingInfo({ ...billingInfo, name: e.target.value})}
                    />
                    
                </Grid>

                 <Grid item xs={12} >
                    <TextField
                        type="text"
                        label='Card Number'
                        name="cardNumber"
              
                        fullWidth
                        value={billingInfo?.cardNumber}
                        onChange={(event) =>
                            setBillingInfo({ ...billingInfo, cardNumber: event.target.value })
                        }
                        />
                    </Grid>
                      <Grid item xs={12} sx={{ display:'flex',gap:'0.5rem', margin:'auto',justifyContent:'center'}}>
                    <TextField
                        type="text"
                        label='Month'
                        name="month"
                         placeholder="00"
                        value={billingInfo?.month}
                        onChange={(event) =>
                        setBillingInfo({ ...billingInfo, month: event.target.value })
                        }
                    />
                     <TextField
                        type="text"
                        label='Year'
                        name="year"
                         placeholder="00"

                        value={billingInfo?.year}
                        onChange={(event) =>
                        setBillingInfo({ ...billingInfo, year: event.target.value })
                        }
                    />
                    </Grid>
                   
                    {/* <Grid item xs={12}>
                    <TextField
                        type="text"
                        label='Year'
                        name="year"
                        value={billingInfo?.year}
                        onChange={(event) =>
                        setBillingInfo({ ...billingInfo, year: event.target.value })
                        }
                    />
                    </Grid> */}
                     <Grid item xs={12} sx={{display:'flex',justifyContent:'end'}}>
                    <TextField
                        type="text"
                        label='CVV Code'
                        name="cardCode"
                        value={billingInfo?.cardCode}
                        onChange={(event) =>
                        setBillingInfo({ ...billingInfo, cardCode: event.target.value })
                        }
                    />
                    </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={(e)=>setLastName(e.target.value)}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                     defaultValue={ billingInfo['email']?billingInfo['email']:''}
                     onChange={(e)=>setBillingInfo({ ...billingInfo, email: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    autoComplete="phone"
                     defaultValue={ billingInfo['phone']?billingInfo['phone']:''}
                     onChange={(e)=>setBillingInfo({ ...billingInfo, phone: e.target.value})}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <TextField
                    // required
                    fullWidth
                    name="department"
                    label="Department"
                    type="text"
                    id="department"
                    autoComplete="department"
                     onChange={(e)=>setDepartment(e.target.value)}
                    />
                </Grid> */}
                {/* <Grid item xs={12}>
                    <TextField
                    // required
                    fullWidth
                    name="notes"
                    label="Notes"
                    type="text"
                    id="notes"
                    autoComplete="notes"
                     onChange={(e)=>setNotes(e.target.value)}
                    />
                </Grid>
                 */}
                {/* <form onSubmit={handleSubmit}> */}
               
                    {/* <Button type="submit" 
                    variant='outlined'
                    disabled={isSubmitting || actionError}>
                        Pay
                    </Button> */}
                    {/* </form> */}


                <Grid item xs={12} sx={{textAlign:'center'}} >
                    <FormControlLabel sx={{margin:'auto'}}
                    control={<Checkbox value={isFollower} onChange={(e)=>{setIsFollower(!isFollower)}} checked={isFollower} color="primary"/>}
                    label="I want to receive information, marketing promotions and updates via email."
                    />
                </Grid>
                </Grid>
                

                {subView === 'billing' &&<Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
                        onClick={handleBack}
                         >
                        Back To Shipping
                    </Button>
                    <React.Fragment>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
                        onClick={ handleSubmit } 
                        disabled={isSubmitting?true:false}
                        >
                        {/* Check{isSubmitting?'ing ':''}out */}
                      Review Order
                        </Button>
                        {actionError}
                    
                        {/* {(open && cardData?.email ) && <ConfirmSignupModal open={open}  order={cart?.items} cart={cart?.items} email={billingInfo?.email}
                        owner_email={owner_email}
                        operating_name={operating_name}
                        // userObj={{firstName, lastName, email, phone, department, inquiryText, isFollower, }}
                        owner={owner}
                        />} */}
                    </React.Fragment>
                     
                    
                </Stack>}
               
                <Grid container justifyContent="flex-end">
                <Grid item>
                    {/* <Link href="#" variant="body2">
                    Already have an account? Sign in
                    </Link> */}
                </Grid>
                </Grid>
            </Box>
    </Container>
  )
}

export default CustomerBilling