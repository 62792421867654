import React ,{  useEffect, useContext,useState }from 'react'
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'
import { StorefrontContext, StorefrontProvider }  from "../context/StorefrontProvider";
import { AppContext } from "../context/AppProvider";
import { Box, Button, Card, Container, Divider, IconButton, Input, InputAdornment, List, ListItem, ListItemIcon, MenuItem, Paper, Select, Stack, Switch, Tooltip, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseButton from './CloseButton';
import ProductList from './ProductList';
import ProductInfoComponent from './ProductInfoComponent';
import AddProductComponent from './AddProductComponent';
import SearchIcon from '@mui/icons-material/Search';
import QrCode from '@mui/icons-material/QrCode';
import { QRCodeContext } from "../context/QRCodeProvider";
import useWindowDimensions from "../hooks/windowDimensions";

const StorefrontConsole = () => {
  const { appData, updateAppData,modalOpen, userData, shops, setShops, orders, setOrders,
    setModalSubView, modalSubView,currentCart, setCurrentCart,
    currentShop, setCurrentShop,currentOrder, setCurrentOrder,
    subView, setSubView,
    currentProduct } = useContext(AppContext)
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
  const { height, width } = useWindowDimensions();
    const [ shopOrders, setShopOrders ] = useState([])
  const [ shopCarts, setShopCarts ] = useState([])
  const [ shopItems, setShopItems ] = useState([])
  let sv = appData?.modalSubView
  // const [ subView, setSubView ] = useState(sv)
  const [ statusColor, setStatusColor ] = useState('')
  const [ showActions ,setShowActions ] = useState(false)
  const [ initialShop ,setInitialShop ] = useState(false)
  const [ filter ,setFilter ] = useState(null)
  const [ searchTerm, setSearchTerm ] = useState(null)
  const [ searchField, setSearchField ] = useState(null)


  const subViews = ['products','orders','carts','subscribers','settings']
  const statusColors = ['draft','cart','complete','pending','hold','processing','printing','fulfilled','shipped']
  const orderStatuses = ['cart','pending','complete','hold','processing','fulfilled','shipped']
  const cartStatuses = ['draft','pending','complete','hold','processing','fulfilled','shipped']
  const orderColumns = ['id', 'status','date']//,'customer']
  const cartsColumns = ['id', 'status','date']//,'customer']
  const shop_settings = ['owner','admin','employees','affiliates','operating_name','owner_email','support_email','collections',]
  const shop_config = ['header_pic','avatar_pic','tagline','featured_collection','featured_item']
  const searchKeyList = ['id','email','item name','price']
  // const filterList = []
  let storeCheck = 0
  let orderCheck = 0
   const fetchShops = async () =>{
        try{ 
          const data = await API.fetchShops(userData)
            if(data){
              await setShops(data)
              await updateAppData({
                ...appData,
                shops
              })
              console.log('Storefronts:', data)
              fetchOrders(data?._id)
              fetchCarts(data?._id)
            }
              storeCheck = 1
          }catch(err){console.log(err)}
    }
   const fetchOrders = async (shop_id) =>{
        try{ 
          const data = await API.fetchOrders(shop_id)
            if(data){
              // await setOrders(data)
              // await updateAppData({
              //   ...appData,
              //   orders
              // })
              console.log('orders:', data)
               setShopOrders(data)
               setShopItems(data?.items)
              // orderCheck = 1
              return data
            }
          }catch(err){console.log(err)}
    }
   const fetchCarts = async (shop_id) =>{
        try{ 
          const data = await API.fetchCarts(shop_id)
            if(data){
              // await setOrders(data)
              // await updateAppData({
              //   ...appData,
              //   orders
              // })
              console.log('carts:', data)
               setShopCarts(data)
              //  setShopItems(data?.items)
              // orderCheck = 1
              return data
            }
          }catch(err){console.log(err)}
    }

  const  handleSearchChange = (e) =>{
    setSearchTerm(e.target.value)
        if(searchTerm){
        let term = filterObjList(searchField,searchTerm,)
     }
  }

  const  handleFieldChange = (e) =>{
    setSearchField(e.target.value)
  }
  
  const filterObjList = (field, term, list) =>{
    const filteredList = list?.filter((obj)=> {return obj[field?.toString()]?.includes(term)})
    if( filteredList?.length ){
      console.log('filteredList', filteredList)
      return filteredList
    }else{
      return list
    }
  }

  const  handleOrderSelect = async (order) =>{
    try{

      setCurrentOrder(order)
      const res = await API.fetchQRByID(order?._qr_id)
        if(res){
          updateQRCodeData(res)
        }else{
          console.log('[Order click]:---NO QR Available---')

        }
      console.log('[Order click]:', order)
      updateAppData({
        modalView:'review-order'
      })
    }catch(err){console.log(err)}
  }
  const  handleCartSelect = (cart) =>{
      setCurrentCart(cart)
      console.log('[Cart click]:', cart)
      // updateAppData({
      //   modalView:'review-Cart'
      // })
      setSubView('review-cart')
      setFilter(null)
  }

  const orderColorCheck = (status) =>{
   switch(status){

     case'draft':
      return 'yellow'
      break;
     case'cart':
      return 'orange'
     break;
     case'complete':
      return 'green'
     break;
     case'hold':
      return 'red'
     break;
     case'processing':
      return 'orange'
     break;
     case'printing':
      return 'blue'
     break;
     case'fulfilled':
      return 'green'
     break;
     case'shipped':
      return 'limegreen'
     break;
   }
  }
  const  toggleStorefront =  async (shop) =>{
    try{

      var val = shop.isActive
      const res = await API.updateShop({...shop,isActive:!val})
      
      if(res)
        {
          setCurrentShop(res)
          updateAppData({
            ...currentShop,
          })
          fetchShops()
        }
    }catch(err){console.log(err)}
  }
  
  useEffect(()=>{
     
    if( storeCheck < 1){
      fetchShops()
    }
  },[currentProduct])

  useEffect(()=>{
    //  if(searchTerm){
    //   filterObjList(searchField,searchTerm,)
    //  }
    
  },[searchTerm])

  useEffect(()=>{
    
    // if( orderCheck < 1){
    //   fetchOrders()
    // }
      // fetchOrders(currentShop?._id) 
  },[shopOrders, currentShop,currentCart,currentOrder,filter ])

  return (
    <Container sx={{position:'relative',//border:'1px solid red',
    padding:'0.5rem',
    maxHeight:'90vh',
    fontFamily:'Gilroy, sans-serif',
    //  margin:'auto',
     width:'100%'}}>
       <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} val={{
                        modalOpen:!modalOpen,
                        modalView:''
                    }} />
        </div>
       <ArrowBackIcon style={{fontSize:'2rem',//position:'absolute',
            left:'2rem', cursor: 'pointer', opacity: '0.5',zIndex:9999,
            top: '0.5rem', marginTop:'0.5rem'}}
            onClick={()=>{
              if(subView === 'review-order' && currentCart){
               setSubView('review-cart')
              }
              else if(subView === 'review-cart'){
                setSubView('carts')
              }else if(subView === 'review-order' && ! currentCart){
                setSubView('orders')
              }else{
                // setSubView('review-cart') 
                updateAppData({
                  ...appData,
                  modalView:'storefronts'
                })
              }
            }}
            
            />

     <Card sx={{position:'absolute',left:'50%',transform:'translate(-50%,0)',top:'0.75rem',padding:'0.5rem',textAlign:'center',
        margin:'auto',width:'fit-content',borderRadius:'10px', border:'1px solid #757575'}}>

          <b>ordering</b>
          <Divider/>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                        <Typography>off</Typography>
                        <Switch defaultValue={currentShop?.isActive} inputProps={{ 'aria-label': 'ant design' }}
                            onChange={(e)=>{
                              toggleStorefront(currentShop)
                                }} checked={currentShop?.isActive} />
                            
                        <Typography>on</Typography>
                </Stack>
              </Card>

              {subView === 'orders' && <Button 
                disabled={true}
                size='small'
                variant='outlined'
                sx={{position:'absolute',right:width>=400 ? '2.5rem':'1rem',top:'3.5rem'}}>
                Create order
                </Button>}

      <Typography>  <h2> {currentShop?.operating_name}</h2> Storefront Console</Typography>
           
            <Paper 
              sx={{position:'relative',display:'flex', 
              flexDirection:'column', width:'100%',padding:'1rem 0',
                boxShadow:'0px 0px 5px 1px rgba( 0, 0, 0 , 0.3 )',
                margin:'auto', maxWidth:'100%',
                minHeight:'30vh',margin:'auto',maxHeight:'70vh',
               overflow:'hidden',//overflowY:'scroll',
                borderRadius:'10px', border:'1px solid #757575'}} 
              onClick={()=>{
                // handleShopClick()
            }}> 
            <Box
              sx={{ gap:'0.5rem',justifyContent:'space-evenly', 
                padding:'0.5rem 0',
                width:'100%', textAlign:'center'}} 
                > 

               <Stack sx={{display:'flex', flexDirection:'row', width:'100%', 
        
                  justifyContent:'space-evenly',marginBottom:'1rem'}}>
                    {subViews?.map((view,i)=>{
                      return (
                        <b  key={i} style={{flexWrap:'wrap', display:'flex',gap:'0.25rem', alignItems:'center',
                          cursor:'pointer', borderBottom: subView === view ?'1px solid #757575':''}} onClick={()=>{
                          setSubView(view)
                          updateAppData({
                            ...appData,
                            modalSubView: view 
                          })
                        }}>
                          {view} 
                          { view === 'orders' && <div style={{fontSize:'0.75rem', fontWeight:"normal"}}>( {shopOrders?.length} )</div>}
                          { view === 'carts' && <div style={{fontSize:'0.75rem', fontWeight:"normal"}}>( {shopCarts?.length} )</div>}
                          { view === 'products' && <div style={{fontSize:'0.75rem', fontWeight:"normal"}}>( {currentShop?.items?.length} )</div>}
                          { view === 'subscribers' && <div style={{fontSize:'0.75rem', fontWeight:"normal"}}>( {currentShop?.subscribers?.length} )</div>}
                        
                        </b>
                      )
                    })}
                  </Stack>

                   <Divider/>
                   <Box sx={{display:'flex',width:'100%',margin:'0.5rem auto',
                   gap:'0.5rem',
                   justifyContent:'center'}}>
                   <Select
                            // labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            // open={gpOpen}
                            // onClose={handleGpClose}
                            // onOpen={handleGpOpen}
                            defaultValue={0}
                            min={0}
                            label="type"
                            name="type"
                            sx={{width:'20%', minWidth:'50px'}}
                            onChange={(e)=>handleFieldChange(e)}
                                
                            >
                               
                                    <MenuItem value={0}>
                                        {/* <em>0</em> */}
                                       via
                                    </MenuItem> 
                                    {searchKeyList?.map((o,j)=>{
                                        return(
                                            <MenuItem value={o} key={j} sx={{flexDirection:'column'}}>
                                                <em>{o}</em>
                            
                                                <Divider />
                                            </MenuItem>
                                        )
                                    })

                                    }
                                
                                    </Select>
                    <Input
                    onChange={handleSearchChange}
                      endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          // onClick={()=>toggleEdit(!edit)}
                          // onMouseDown={()=>toggleEdit(!edit)}
                          edge="end"
                        >
                        {/* <BackspaceIcon />  */}
                        <SearchIcon /> 
                        </IconButton>
                        
                      </InputAdornment>
                    }
                    />
                    </Box>
           {subView === 'settings' &&  <Stack sx={{textAlign: 'center', gap:'0.5rem'}}>
                  <b>Settings</b>
                   <Divider  sx={{marginBottom:'1rem'}}/>
                   <Box sx={{textAlign: 'left'}}>
                   {Object.keys(currentShop)?.map((key,i)=>{
                     if(shop_settings?.includes(key)){
                      if( !Array.isArray(currentShop[key]) && typeof currentShop[key] !== 'object' ){
                       return(
                           <span key={i}>
                              <b>
                              {helpers.splitKey(key,'_',' ')}:  
                              </b> &nbsp;
                            {currentShop[key]}
                             {/* {typeof currentShop[s] !== 'object' || 'array' ?currentShop[s]:''} */}
                           </span>
                         )
                       }
                       } else if( Array.isArray(currentShop[key]) ){
                             return(
                               <Box>
                                   <b>
                                       {helpers.splitKey(key,'_',' ')}: 
                                   </b>
                                   <List sx={{display:'flex',gap:'0.5rem', justifyContent:'space-evenly',overflow:'hidden',overflowY:'scroll'}}>
                                            { currentProduct[key] && currentProduct[key]?.map((el,j)=>{
                                                return(<ListItem key={j} style={{cursor: key === 'images' ?'pointer':"" }}
                                                    onClick={()=>{
                                                        if( key ==='images'){
                                                            console.log('[Image Click]',el )
                                                        }
                                                     }}>
                                                        {el}
                                                    {/* {imageList.includes(key) && <img src ={el} width='auto' height='80px'/>}
                                                    {!imageList.includes(key) && <ItemFieldComponent field={key} value={el} index={j} />} */}
                                                    </ListItem>)
                                            })}
                                        <Typography>
                                        </Typography>
                                    </List>
                               </Box>
                           )
                     }
                      })}
                   {Object.keys(currentShop?.config)?.map((key,i)=>{
                     if(shop_config?.includes(key)){
                      if( !Array.isArray(currentShop[key]?.config) && typeof currentShop[key]?.config !== 'object' ){
                       return(
                           <span key={i}>
                              <b>
                              {helpers.splitKey(key,'_',' ')}:  
                              </b> &nbsp;
                            {currentShop[key]?.config[key]}
                             {/* {typeof currentShop[s] !== 'object' || 'array' ?currentShop[s]:''} */}
                           </span>
                         )
                       }
                       } else if( Array.isArray(currentShop[key]?.config) ){
                             return(
                               <Box>
                                   <b>
                                       {helpers.splitKey(key,'_',' ')}: 
                                   </b>
                                   <List sx={{display:'flex',gap:'0.5rem', justifyContent:'space-evenly',overflow:'hidden',overflowY:'scroll'}}>
                                            { currentProduct[key]?.config && currentProduct[key]?.config?.map((el,j)=>{
                                                return(<ListItem key={j} style={{cursor: key === 'images' ?'pointer':"" }}
                                                    onClick={()=>{
                                                        if( key ==='images'){
                                                            console.log('[Image Click]',el )
                                                        }
                                                     }}>
                                                        {el}
                                                    {/* {imageList.includes(key) && <img src ={el} width='auto' height='80px'/>}
                                                    {!imageList.includes(key) && <ItemFieldComponent field={key} value={el} index={j} />} */}
                                                    </ListItem>)
                                            })}
                                        <Typography>
                                        </Typography>
                                    </List>
                               </Box>
                           )
                     }
                      })}
                    </Box>
                    
                
          </Stack>}
              {/* <Stack sx={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
              
              
            </Stack> */}
               
            {subView === 'products' &&<Stack sx={{ gap:'0.25rem',padding:'0.5rem',overflowX:'hidden', overflowY:'scroll',margin: 'auto'}}>
                     {/* <b>
                      Products ( {currentShop?.items?.length} )
                    </b> */}
                    <Button sx={{margin:'auto'}}
                      onClick={()=>{
                        
                        setSubView('add-product')
                        setModalSubView('add-product')
                        updateAppData({
                          ...appData,
                          modalSubView
                      })
                      
                      }}
                    >Add Product</Button>
                    <Divider  sx={{marginBottom:'1rem'}}/>
                     <ProductList items={currentShop?.items}/>
                    </Stack>}
               
            {subView === 'add-product' &&<Stack sx={{ gap:'0.25rem',padding:'0.5rem',overflowX:'hidden', overflowY:'scroll',height:'70vh',margin: 'auto'}}>
                    
                    {/* <Button sx={{margin:'auto'}}
                      onClick={()=>{
                        setSubView('add-product')
                        updateAppData({
                          ...appData,
                          modalSubView:'add-product'
                      })
                      
                      }}
                    >Add Product</Button> */}
                    <Divider  sx={{marginBottom:'1rem'}}/>
                     <AddProductComponent items={currentShop?.items} view={subView}/>
                    </Stack>}

            {subView === 'subscribers' && <Stack sx={{gap:'0.25rem'}}>
                 
                        <div style={{cursor:'pointer',marginTop:'1rem',zindex: 9999}}onClick={()=>{setShowActions(!showActions)}}>Actions  <img className={`${showActions?'rot180':'rot-180'}` }
                            src={`/assets/arrow-${showActions?'up':'down'}.svg`} style={{height:'0.5rem', alignSelf: 'center',opacity:showActions?'1':'0.3'}} alt="Clear Current Asset"/></div>
                              {showActions && <Box sx={{display:'flex', flexDirection:'row',padding:'0.5rem', gap:'0.25rem',justifyContent:'center',height:'5rem'}}>
                          <Button> 
                                Contact
                          </Button>
                          <Button> 
                                Browse Orders 
                          </Button>
                          <Button> 
                                Delete
                          </Button>
                         
                      </Box>}
                       <Divider sx={{marginBottom:'1rem'}}/>
                      <List sx={{gap:'0.25rem',textAlign:'left', overflowY:'scroll', height:'50vh'}}>

                      {currentShop?.subscribers ? currentShop?.subscribers?.map((f,j)=>{
                        return (
                          <ListItem key={'follower-'+j} 
                            sx={{display:'flex',alignItems:'center',
                              gap:'0.5rem',cursor:'pointer',height:'1.5rem', 
                              
                              padding:'0.5rem',justifyContent:'start', 
                              '&:hover': {background:'rgba(144, 254, 195,0.5)'},}}>
                                {j+1}
                             <Input type='checkbox'/> {f}
                                  <Divider sx={{}}/>
                            </ListItem>
                          )
                        }): 'no subscribers available'}
                      </List>
                    </Stack>}
                   
                   {subView === 'orders' && <Stack sx={{position:'relative',padding:'0.25rem',
                    width:'100%',height:'100%',margin:'auto'}}>
                  <p style={{cursor:'pointer', width:'fit-content',padding:'0.25rem 0.5rem', 
                    margin:'0.5rem auto',border:'1px solid #757575',borderRadius:'3px'}} onClick={()=>{
                                setFilter(null)
                              }}>

                                  clear
                              </p>
                    <b>
                      filter by:
                      </b>
                       <Box sx={{position:'relative',height:'100%', 
                       width:'100%',margin:'auto',
                       maxWidth:'300px',
                        overflow:'hidden',//overflowY:'scroll',
                        padding:'0.5rem',display:'flex', gap:'0.5rem',
                        flexWrap:'wrap',justifyContent:'center',
                        // fontSize:'0.7rem',
                          margin:'0.5rem auto'}}>

                        {statusColors?.map((f,i)=>{

                            return(
                              <p key={i} style={{cursor:'pointer', //color: helpers.orderColorCheck(f),
                                borderBottom: filter === f  ?'1px solid #757575':''}} onClick={()=>{
                                setFilter(f)
                              }}>
                                 {i>0 ? '| ':''}
                                  {f}
                              </p>
                            )
                        })}
                       </Box>
                        

                       <Divider sx={{marginBottom:'1rem'}}/>
                       <Box sx={{position:'relative',height:'100%',display:'flex', justifyContent:'space-evenly',
                          width:'100%',
                          margin:'auto'}}>
                        {orderColumns.map((h,i)=>{

                            return(
                              <b key={i} style={{flex: i<1 ? 1.5 :1}}>

                                  {h}
                              </b>
                            )
                        })}
                       </Box>
                       <Divider  sx={{marginBottom:'1rem'}}/>
                       <Paper sx={{height:'100%',width:'100%',maxHeight:'45vh',overflow:'hidden', flex:1,paddding:'0.25rem'}}>

                        <List sx={{display:'flex',flexDirection:'column',position:'relative',
                        width:'100%', height:'100%',maxHeight:'40vh',//margin:'0', 
                            gap:'0.5rem',overflow:'hidden',overflowY:'scroll'}}>
                      {shopOrders ? filterObjList( searchField, searchTerm, shopOrders )?.map((order,k)=>{
                        let status_color = helpers.orderColorCheck(order?.status)
                        if((filter && order?.status === filter) || !filter){
                        return (
                          <Tooltip title={order?._id} sx={{margin:'0.5rem auto'}} >

                            <ListItem id='list-item'
                            key={'order-'+k} style={{
                                cursor:'pointer',minHeight:'1.5rem',
                                display:'flex',
                                // flexDirection:'row',
                                flex:1,
                                width:'100%',
                                // '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                gap:'0.5rem'
                                }}
                                >
                                  <Input type='checkbox'/> 
                                  <Card style={{cursor:'pointer',height:'1.5rem',
                                  '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                display:'flex',
                                // flexDirection:'row',
                                flex:1,
                                // gap:'0.5rem',
                                width:'100%',
                                //  border:'1px solid red',
                                padding:'0.5rem 0.25rem',
                                // justifyContent:'space-between',
                                // overflow:'hidden'
                              }}
                              onClick={()=>{
                                handleOrderSelect(order)
                              }}
                                >

                                  <span style={{display:'flex',width:'40%', flex:1,
                                  //  border:'1px solid blue'
                                  
                                  }}>
                                  <span style={{display:'flex',
                                        // textOverflow: 'ellipsis',
                                        // whiteSpace: 'nowrap',
                                        gap:'0.5rem',flex:1}}>
                                    {k+1} |&nbsp;
                                  <span style={{overflow: 'hidden',
                                          maxWidth: '150px',
                                          minWidth: '100px',
                                          textOverflow: 'ellipsis',
                                          width: '100px',
                                      
                                          // textAlign:'left',
                                          whiteSpace: 'nowrap',
                                          }}>
                                            {order?._id}
                                            
                                          </span>
                                          <span style={{display:'flex',flex:1,justifyContent:'space-evenly',margin:'auto'}}>
                                          {order?.type === 'music'&& <MusicNoteIcon sx={{width: '1rem'}}/>}
                                      {order?.qr_id !== 'n/a'&& <QrCode sx={{width: '1rem',color: !order?.qr_id ? 'red':'black'}}/>}
                                      {order?.asset_url !== 'n/a' && <BadgeIcon sx={{width: '1rem',color: !order?.asset_url ? 'red':'black'}}/>}
                                      {(order?.printFile !== 'n/a' && order?.type !== 'music') && <DriveFileMoveIcon sx={{width: '1rem',color: !order?.printFile ? 'red':'black'}}/>}
                                    </span>
                                    </span>
                                  <span style={{width:'100%',minWidth:'150px',
                                      display:'flex',
                                      justifyContent:'space-evenly',
                                      // border:'1px solid green', 
                                      flex:1}}>
                                        <span style={{color: status_color, textAlign:'left'}}>
                                          {order?.status}
                                        </span>
                                        <span style={{
                                          maxWidth: '150px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          width: '150px',
                                          }}>
                                            {order?.createdAt?.toLocaleString()}
                                        </span>
                                    </span>
                                  
                                    </span>
                                  </Card>
                            </ListItem>
                          </Tooltip>
                        )
                      }
                      }): 'no orders available'}
                    </List>
                       </Paper>
                    </Stack>}

                   {subView === 'carts' && <Stack sx={{position:'relative',padding:'0.25rem',
                    width:'100%',minWidth:'400px',height:'100%',margin:'auto'}}>
                  <p style={{cursor:'pointer', width:'fit-content',padding:'0.25rem 0.5rem', 
                    margin:'0.5rem auto',border:'1px solid #757575',borderRadius:'3px'}} onClick={()=>{
                                setFilter(null)
                              }}>

                                  clear
                              </p>
                    <b>
                      filter by:
                      </b>
                       <Box sx={{position:'relative',height:'100%', 
                       width:'100%',margin:'auto',
                       maxWidth:'300px',
                        overflow:'hidden',//overflowY:'scroll',
                        padding:'0.5rem',display:'flex', gap:'0.5rem',
                        flexWrap:'wrap',justifyContent:'center',
                        // fontSize:'0.7rem',
                          margin:'0.5rem auto'}}>

                        {cartStatuses?.map((f,i)=>{

                            return(
                              <p key={i} style={{cursor:'pointer', borderBottom: filter === f  ?'1px solid #757575':''}} onClick={()=>{
                                setFilter(f)
                              }}>
                                 {i>0 ? '| ':''}

                                  {f}
                              </p>
                            )
                        })}
                       </Box>
                        

                       <Divider sx={{marginBottom:'1rem'}}/>
                       <Box sx={{position:'relative',height:'100%',display:'flex', justifyContent:'space-evenly',
                          width:'100%',
                          margin:'auto'}}>
                        {cartsColumns.map((h,i)=>{

                            return(
                              <b key={i} style={{flex: i<1 ? 1.5 :1}}>

                                  {h}
                              </b>
                            )
                        })}
                       </Box>
                       <Divider  sx={{marginBottom:'1rem'}}/>
                       <Paper sx={{height:'100%',maxHeight:'45vh',overflow:'hidden',padding:'0.25rem',flex:1}}>

                        <List sx={{display:'flex',flexDirection:'column',position:'relative',
                        width:'100%', height:'100%',maxHeight:'40vh',//margin:'0', 
                            gap:'0.5rem',overflow:'hidden',overflowY:'scroll'}}>
                      {shopCarts ? shopCarts?.map((cart,k)=>{
                        let status_color = helpers.orderColorCheck(cart?.status)
                       if((filter && cart?.status === filter) || !filter){
                          return (
                            <Tooltip title={cart?._id} sx={{margin:'0.5rem auto'}}  >

                              <ListItem id='list-item'
                              key={'cart-'+k} style={{
                                  cursor:'pointer',minHeight:'1.5rem',
                                  display:'flex',
                                  // flexDirection:'row',
                                  flex:1,
                                  width:'100%',
                                  maxWidth:'800px',
                                  margin:'auto',
                                  gap:'0.5rem',
                                  // '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                  
                                  }}
                                  >
                                    <Input type='checkbox'/> 
                                    <Card style={{cursor:'pointer',height:'1.5rem',
                                    '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                    // flexDirection:'row',
                                    flex:1,
                                    // gap:'0.5rem',
                                    width:'100%',
                                    maxWidth:'800px',
                                    //  border:'1px solid red',
                                    padding:'0.5rem 0.5rem',
                                    display:'flex',
                                    justifyContent:'space-evenly',
                                      // overflow:'hidden'
                                    }}
                                    onClick={()=>{
                                      handleCartSelect(cart)
                                    }}
                                      >
    
                                    <span style={{display:'flex', //flex:1,
                                    //  border:'1px solid blue'

                                    // justifyContent:'center',
                                    }}>
                                    <span style={{display:'flex',
                                          // textOverflow: 'ellipsis',
                                          // whiteSpace: 'nowrap',
                                          // width:'40%',
                                          // justifyContent:'space-between',
                                          gap:'0.5rem',//flex:1
                                          //  border:'1px solid blue', 
                                          }}>
                                            <span sx={{display:'flex',flexDirection:'row'}}>

                                      {k+1} |&nbsp;
                                            </span>

                                    <span style={{overflow: 'hidden',
                                            maxWidth: '150px',
                                            minWidth: '100px',
                                            textOverflow: 'ellipsis',
                                            width: '15%',
                                            // display:'flex',
                                            // textAlign:'left',
                                            whiteSpace: 'nowrap',}}>
                                              {cart?._id}
                                            </span>
                                        <b style={{width:'90px',textAlign:'center',
                                          // border:'1px solid red',
                                        }}>{cart?.items?.length} items</b>
                                      </span>

                                    <span style={{//width:'100%',minWidth:'200px',
                                        display:'flex',
                                        justifyContent:'space-evenly',
                                        gap: '0.5rem',
                                        // border:'1px solid green', 
                                        // flex:1
                                        }}>
                                          <span style={{color: status_color, textAlign:'center', width: '70px',
                                          // border:'1px solid red'

                                          }}>
                                            {cart?.status}
                                          </span>
                                          <span style={{overflow: 'hidden',
                                            maxWidth: '150px',
                                            textOverflow: 'ellipsis',
                                            width: '150px',
                                            whiteSpace: 'nowrap',}}>
                                              {cart?.createdAt?.toLocaleString()}
                                          </span>
                                      </span>
                                      
                                      </span>
                                    </Card>
                              </ListItem>
                            </Tooltip>
                          )
                       }
                      }): 'no carts available'}
                    </List>
                       </Paper>
                    </Stack>}

                    {subView === 'review-cart' && <Stack sx={{position:'relative',padding:'0.25rem',
                    width:'100%',height:'100%',margin:'auto',}}>
                  <p style={{cursor:'pointer', width:'fit-content',padding:'0.25rem 0.5rem', 
                    margin:'0.5rem auto',border:'1px solid #757575',borderRadius:'3px'}} onClick={()=>{
                                setFilter(null)
                              }}>

                                  clear
                              </p>
                     <b>
                      filter by:
                      </b>
                       <Box sx={{position:'relative',height:'100%', 
                       width:'100%',margin:'auto',
                       maxWidth:'300px',
                        overflow:'hidden',//overflowY:'scroll',
                        padding:'0.5rem',display:'flex', gap:'0.5rem',
                        flexWrap:'wrap',justifyContent:'center',
                        // fontSize:'0.7rem',
                          margin:'0.5rem auto'}}>

                        {cartStatuses?.map((f,i)=>{

                            return(
                              <p key={i} style={{cursor:'pointer',borderBottom: filter === f  ?'1px solid #757575':''}} onClick={()=>{
                                setFilter(f)
                              }}>
                                {i>0 ? '| ':''}
                                  {f}
                              </p>
                            )
                        })}
                       </Box>
                        

                       <Divider sx={{marginBottom:'1rem'}}/>
                        cart id: {currentCart?._id}
                       <Divider sx={{marginBottom:'1rem'}}/>
                       <Box sx={{position:'relative',height:'100%',display:'flex', justifyContent:'space-evenly',
                          width:'100%',
                          maxWidth:'500px',
                          margin:'auto'}}>
                        {cartsColumns.map((h,i)=>{

                            return(
                              <b key={i} style={{flex: i<1 ? 1.5 :1}}>

                                  {h}
                              </b>
                            )
                        })}
                       </Box>
                      
                       <Divider  sx={{marginBottom:'1rem'}}/>
                       <Paper sx={{height:'100%',maxHeight:'45vh',overflow:'hidden',padding:'0.25rem',flex:1}}>

                        <List sx={{display:'flex',flexDirection:'column',position:'relative',
                        width:'100%', height:'100%',maxHeight:'40vh',//margin:'0', 
                        padding:'0.5rem',
                            gap:'0.5rem',overflow:'hidden',overflowY:'scroll'}}>
                      {currentCart?.items?.length >0 ? 
                      
                      shopOrders?.map((order,k)=>{
                      // currentCart?.items?.map((order,k)=>{
                        let status_color = helpers.orderColorCheck(order?.status)
                        if(currentCart?.items?.includes(order?._id)){
                          if((filter && order?.status === filter) || !filter){

                          return (
                            <Tooltip title={order?._id} sx={{margin:'0.5rem auto'}}  >

                              <ListItem id='list-item'
                              key={'order-'+k} style={{
                                  cursor:'pointer',minHeight:'1.5rem',
                                  display:'flex',
                                  // flexDirection:'row',
                                  flex:1,
                                  width:'100%',
                                  // '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                  gap: '0.5rem'
                                  }}
                                  >
                                    <Input type='checkbox'/>
                                    <Divider sx={{}}/>
                                    <Card style={{cursor:'pointer',height:'1.5rem',
                                    '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                    display:'flex',
                                    // flexDirection:'row',
                                    flex:1,
                                    // gap:'0.5rem',
                                    width:'100%',
                                    backgroundColor:'rgba(255,255,255,0.5)',
                                    //  border:'1px solid red',
                                    padding:'0.5rem 0.5rem',
                                    // justifyContent:'space-between',
                                    // overflow:'hidden'
                                    }}
                                    onClick={()=>{
                                      handleOrderSelect(order)
                                    }}
                                    >
                        
                                    <span style={{display:'flex',width:'40%', flex:1,
                                    //  border:'1px solid blue'
                                    
                                    }}>
                                    <span style={{display:'flex',
                                          // textOverflow: 'ellipsis',
                                          // whiteSpace: 'nowrap',
                                          gap:'0.5rem',flex:1}}>
                                      {k+1} |&nbsp;
                                    <span style={{overflow: 'hidden',
                                            maxWidth: '150px',
                                            minWidth: '100px',
                                            textOverflow: 'ellipsis',
                                            width: '15%',
                                            // display:'flex',
                                            // textAlign:'left',
                                            whiteSpace: 'nowrap',}}>
                                              {order?._id}
                                            </span>
                                            <span style={{display:'flex',flex:1,justifyContent:'space-evenly',margin:'auto'}}>

                                                {order?.type === 'music'&& <MusicNoteIcon sx={{width: '1rem'}}/>}
                                                {order?.qr_id !== 'n/a'&& <QrCode sx={{width: '1rem',color: !order?.qr_id ? 'red':'black'}}/>}
                                                {order?.asset_url !== 'n/a' && <BadgeIcon sx={{width: '1rem',color: !order?.asset_url ? 'red':'black'}}/>}
                                                {(order?.printFile !== 'n/a' && order?.type !== 'music') && <DriveFileMoveIcon sx={{width: '1rem',color: !order?.printFile ? 'red':'black'}}/>}
                                            </span>
                                      </span>
                                    <span style={{width:'100%',minWidth:'150px',
                                        display:'flex',
                                        justifyContent:'space-evenly',
                                        // border:'1px solid green', 
                                        flex:1}}>
                                          <span style={{color: status_color, textAlign:'center'}}>
                                            {order?.status}
                                          </span>
                                          <span style={{overflow: 'hidden',
                                            maxWidth: '150px',
                                            textOverflow: 'ellipsis',
                                            width: '150px',
                                            whiteSpace: 'nowrap',}}>
                                              {order?.createdAt?.toLocaleString()}
                                          </span>
                                      </span>
                                    
                                    </span>
                                    </Card>
                              </ListItem>
                            </Tooltip>
                          )
                        }
                        }
                      }): 'no items available'}
                      <Box sx={{height:'15rem',padding:'0.5rem'}}>
                          &nbsp;
                      </Box>
                    </List>
                       </Paper>
                    </Stack>}
                     
            </Box>
            </Paper>
          

    </Container>
  )
}

export default StorefrontConsole