import React, { useState, createContext } from "react";
// const Default_QR = require('../options_02.json')
const defaultQR = require('../defaults/BaseShopQR.json')
const orderDefault = require('../defaults/OrderDefault.json')
// Create a new context
export const StorefrontContext = createContext();


// Create a context provider component
export const StorefrontProvider = ({ children }) => {

    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ department, setDepartment ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ inquiryText, setInquiryText ] = useState('')
    const [ isFollower, setIsFollower ] = useState(true)
    const [ storefrontData, setStorefrontData] = useState({})
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ menuOpen, setMenuOpen ] = useState(false)
    const [ cartOpen, setCartOpen ] = useState(false)
    const [ framingOpen, setFramingOpen ] = useState(false)

    const [ view , setView ] = useState('home')
    const [ currentProduct , setCurrentProduct ] = useState(null)
    const [ currentOrder, setCurrentOrder] = useState(orderDefault)
    const [ selectedSize, setSelectedSize ] = useState('l')
    const [ buildStep, setBuildStep ] = useState(null)
    const [ subView , setSubView ] = useState('')
    const [ shop, setShop ] = useState(null)
    const [ owner, setOwner ] = useState(null)
    const [ items, setItems ] = useState(null)
    const [ customer, setCustomer ] = useState({})
    const [ billingInfo, setBillingInfo ] = useState({})
    const [ shippingInfo, setShippingInfo ] = useState({})

    const [ cart, setCart ] = useState({})
  
    // const [ tempOrder, setTempOrder ] = useState([...t_ord])
    const [ tempOrder, setTempOrder ] = useState([])

    const  updateCart = (newData) => {
      setCart((prevState) => ([
        // ...prevState,
        ...newData
      ]));
  
    };
    const  updateTempOrder = (newData) => {
      setTempOrder((prevState) => ([
      //   ...prevState,
        ...newData
      ]));
  
    };

  const  updateStorefrontData = (newData) => {

    setStorefrontData((prevState) => ({
    //   ...prevState,
    //   ...newData,
        order: [...tempOrder],
        firstName,
        lastName,
        email,
        phone,
        department,
        notes,
        inquiryText,
        isFollower,
        cart,
        modalOpen,
        menuOpen,
        view,
        shop,
        owner, 
        currentProduct,
        items,
        currentOrder,
        selectedSize,
        buildStep, 
        defaultQR,
        orderDefault,
        cartOpen,
        framingOpen, 
        customer,
        billingInfo, 
        shippingInfo, 
    }));
 
  };


  return (
    <StorefrontContext.Provider value={{ 
        storefrontData, 
        setStorefrontData,
        updateStorefrontData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
        firstName, 
        setFirstName,
        lastName, 
        setLastName,
        email, 
        setEmail,
        phone, 
        setPhone,
        department, 
        setDepartment,
        notes, 
        setNotes, 
        inquiryText, 
        setInquiryText,
        isFollower, 
        setIsFollower,
        cart,
        setCart,
        updateCart,
        modalOpen, 
        setModalOpen,
         menuOpen,
        setMenuOpen,
        view, 
        setView,
        subView, 
        setSubView,
        cart, 
        setCart,
        shop, 
        setShop,
        currentProduct, 
        setCurrentProduct,
        items,
        setItems,
        currentOrder, 
        setCurrentOrder,
        selectedSize, 
        setSelectedSize,
        buildStep, 
        setBuildStep,
        owner, 
        setOwner,
        defaultQR,
        orderDefault,
        cartOpen, 
        setCartOpen,
        framingOpen, 
        setFramingOpen,
        customer,
        setCustomer,
        billingInfo,
        setBillingInfo,
        shippingInfo, 
        setShippingInfo 
        }}>
            
      {children}
    </StorefrontContext.Provider>
  );
};
