import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal,Box, Button, Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState } from 'react'
import CloseButton from '../../../components/CloseButton'
import TrackDownload from './TrackDownload'

const PinModal = (props) => {
    const { setPinModalOpen, pinModalOpen, item ,album} = props
    const [ pin, setPin ] = useState()
    const [ isLocked, setIsLocked ] = useState(true)
    const [ actionError, setActionError ] = useState('')

    const handlePinCheck = () =>{
        console.log('pin:',pin)
        console.log('item pin:',item?.pin)
        if( !pin ){
            setActionError('Please Enter Pin')
        }
        if( pin?.toString() === item?.pin?.toString() ){
            // setPinModalOpen(false)
            setActionError('')
            setIsLocked(false)
        }else{
            setActionError('Incorrect Pin')
        }
    }
  return (
    <>
        <Modal 
            open={pinModalOpen}
            onClose={()=>setPinModalOpen(!pinModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                    height:'200px',
                    backgroundColor:'#FFF',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem'
                    
                    }}>
                        <Box sx={{position:'absolute', top:'-0.5rem', right:'-0.5rem'}}>
                            <CloseButton func={setPinModalOpen} val={pinModalOpen}
                        
                            />
                        </Box>
                       {isLocked && <Stack sx={{justifyContent:'center',
                            textAlign:'center',
                            alignItems:'center',
                            gap:'0.5rem',}}>

                       <h3 style={{fontWeight:'bold'}}>
                       Please Enter Provided Pin To Download!
                       </h3> 
                       <Input placeholder='pin' sx={{
                            display:'flex',
                            maxLength:6,
                            width:'50%',
                            textAlign:'center'
                        }} onChange={(e)=>{
                            setPin(e.target.value)
                       }}
                       />
                       <Button variant='outlined' onClick={handlePinCheck}>
                        submit
                       </Button>
                       <Typography sx={{color:'red'}}>
                        {actionError}
                       </Typography>
                        </Stack>}

                        {!isLocked &&
                        <TrackDownload album={album} track={item}/>
                        }
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default PinModal