import { Avatar, Box, Container, Typography } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import Header from './storefrontComponents/Header'
import AboutSection from './storefrontComponents/AboutSection'
import Products from './storefrontComponents/Products'
import Copyright from '../components/Copyright'
import CartModal from './storefrontComponents/CartModal'
import InterestWidget from './storefrontComponents/InterestWidget'

import { StorefrontContext, StorefrontProvider }  from "../context/StorefrontProvider";
import { QRCodeContext } from "../context/QRCodeProvider";

import CollectionView from './CollectionView'
import AboutView from './AboutView'
import ContactView from './ContactView'

import { useParams } from "react-router-dom";
import * as API from '../utils/APICalls'
import ProductView from './storefrontComponents/ProductView'
import ReviewSection from './storefrontComponents/ReviewSection'
import { HowItWorks } from './storefrontComponents/HowItWorks'
import BuildShirt from './storefrontComponents/BuildShirt'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreviousViewButton from './storefrontComponents/PreviousViewButton'
import CheckoutModal from './storefrontComponents/CheckoutModal'
import CompleteOrder from './storefrontComponents/CompleteOrder'



const Storefront = (props) => {
    let param_id =  useParams()?.id?.toString()
    const _id = param_id ? param_id : props.id
    // const [cartOpen, setCartOpen ] = useState(true)
   
    // const [ modalOpen, setModalOpen ] = useState(false)
    // const [cart, setCart ] = useState(false)
    const { modalOpen, setModalOpen,cart,setCart,
        order, view, setView, shop, setShop, currentOrder,
        storefrontData, setStorefrontData, currentProduct,
        items, setItems,owner, setOwner,cartOpen, setCartOpen,
        subView
    } = useContext(StorefrontContext);

        const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
        


    const fetchStorefront = async (id) =>{
        try{
            console.log('fetchStorefront() :',_id)
            const data = await API.fetchShop({_id})
            if (data) {
                await setShop(data)
                // await setItems(data?.items)
                await setItems(data?.items?.filter((item)=> item?.isActive === true))
                await setOwner(data?.owner)
                await setStorefrontData({...storefrontData, shop,items,owner})
                console.log('fetchStorefront() :',data)
            }
        }catch(err){console.log(err)}
    }
   
    

    const layout = [
        {name: 'header',component:<Header conf={{...shop?.config}} shop={shop}/>},
        {name: 'about',component:<AboutSection conf={{...shop?.config}}/>},
        {name: 'spread',component:<CollectionView conf={{...shop?.config}} shop={shop} items={shop?.items?.filter((item)=> item?.isActive == true)} collection={shop?.collections?.filter((collection)=> collection?._id?.toString() === shop?.config?.featured_collection)[0]}/>},
        {name: 'Products',component:<Products conf={{...shop?.config}} items={shop?.items?.filter((item)=> item?.isActive == true)} setModalOpen={setModalOpen} 
        cartOpen={cartOpen}/>},
        {name: 'reviews',component:<ReviewSection conf={{...shop?.config}} items={shop?.items?.filter((item)=> item?.isActive == true)} 
        setModalOpen={setModalOpen} view={'shop'}/>},
        {name: 'spread',component:<CollectionView conf={{...shop?.config}} shop={shop} items={shop?.items?.filter((item)=> item?.isActive == true)} collection={shop?.collections?.filter((collection)=> collection?._id?.toString() === shop?.config?.highlight_collection)[0]}/>},
    ]
    useEffect(() =>{
        if( !shop){
            fetchStorefront()
        }
    },[shop])

    useEffect(() =>{
   
    },[storefrontData,qrCodeData,currentOrder])

    
  return (
    <Container sx={{
        display:'flex',
        flexDirection:'column',
        position:'relative',
        padding:'0.5rem',
        width: '100%',
        // minWidth: '350px'
        }}>
            <div id="scroll-container" style={{paddingTop:'0.25rem', backgroundColor:'rgba(0, 0, 0, 0.9)',
            borderBottom:'4px ridge rgba(255,247,123,0.7)', //boxShadow:'inset 0px 0px 5px 3px rgba(255,255,255,0.5)', 
            boxShadow:`0px -4px 10px 5px rgba(0, 0, 0, 0.5),inset 0px 0px 10px 4px rgba(255,247,123,${0.3})`,
            borderTop:'2px ridge rgba(255,247,123,0.7)', marginTop:'0.25rem', backdropFilter:'blur(10px)', transition:'2s ease-in-out'}}>  
        <div id={!modalOpen?"scroll-text":''} style={{padding:'0',textAlign:'center'}}>
            <Typography sx={{fontSize:'0.75rem',color:"#fff", whiteSpace:'no-wrap'}}// dangerouslySetInnerHTML={{__html:scrollComp}}>
                >
               Welcome! Be sure to check back or subscribe for updates!
            {/* <img src='/assets/MedMan_Flat.png' height='20rem' width={'auto'} style={{background:' rgb(255,255,255)',
                background: 'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3) 35%, rgba(255,255,255,0) 81%)'}}/> */}
                </Typography>
        </div>
        </div>
              {/* <InterestWidget ModalOpen={cartOpen} 
                setModalOpen={setModalOpen} modalView={'cart'}  
                subView={'cart'}
                cart={cart} shop={shop} 
                setCart={setCart}
                />
             */}
              <BuildShirt ModalOpen={modalOpen} 
                setModalOpen={setModalOpen} modalView={'cart'}  
                subView={'build'}
                item={currentProduct}
                cart={cart} shop={shop} 
                setCart={setCart}
                />
                
                <CheckoutModal cartOpen={cartOpen} 
                    setCartOpen={setCartOpen} modalView={'cart'}  subView={'cart'}
                    cart={cart} shop={shop} setCart={setCart}
                />
                {/* <Avatar sx={{ 
                position: 'absolute',
                padding:'0.5rem',
                left:'50%',top:'33%',
                transform: 'translate(-50%,-50%)',
                bgcolor: '#868686', 
                width:'150px', 
                height:'150px',
                border:'3px solid #000',
                // marginBottom:'-1rem', 
                zIndex:9999,
                backgroundColor:'#fff',
                borderRadius:'10px',
                boxShadow:'0px 0px 15px 5px rgba(0,0,0,0.8)'}} 
                src={config?.avatar_pic}>
                </Avatar> */}
            <Box sx={{
                display:'flex', 
                flexDirection:'column',
                marginBottom:'0.75rem', 
                zIndex:0,
                gap:'0.5rem'}}>
                
        {view === 'home' && layout?.map((e) => {
            return (
                e?.component
            )
        }
        )}
        {view !== 'home' &&<Header conf={{...shop?.config}} shop={shop}/>}
        {/* {view !== 'home' && <ArrowBackIcon style={{fontSize:'2rem',position:'absolute',left:'2rem',top: shop?.config?.headerHeight, marginTop:'3.25rem'}}/>} */}
        {view !== 'home' && <PreviousViewButton config={shop?.config} />}
        {view === 'collection' && <CollectionView conf={{...shop?.config}} shop={shop} items={shop?.items?.filter((item)=> item?.isActive == true)} collection={shop?.collections?.filter((collection)=> collection?.name?.toString() === subView)[0]}/>}
        {view === 'thank you' && <CompleteOrder/>}
        {view === 'about' && <AboutView/>}
        {view === 'how it works' && <HowItWorks/>}
        {view === 'contact' && <ContactView />}
        {view === 'product' && <ProductView />}
            </Box>

        <Copyright view='signupSheet' />
    </Container>
  )
}

export default Storefront

// const shop = {
    //     owner:'65f056671267a2925b3f6354', 
    //     admin:['65f056671267a2925b3f6354'], 
    //     employees:['65f056671267a2925b3f6354'], 
    //     operating_name:'Parallaxr', 
    //     owner_email:'aj@rexentropy.com', 
    //     support_email:'parallaxr.brand@rexentropy.com', 
    //     campaingName:'Parallaxr.Shop',
    //     items: ['0','1','2'],
    //     collections: [
    //         {
    //             name:'basics',
    //             items:['1']
    //         },
    //         {
    //             name:'unique QR',
    //             items:['1']
    //         },
    //         {   
    //             name:'personalized QR',
    //             items:['0','2']
    //         },
    //     ],
    //     subscribers:['aj@rexentropy.com']

    // }
    

    // const items = [
    //     {
    //         _id:'1',
    //         name:'Porthole To My Heart', 
    //         subtitle:'w/ dynamic QR code', 
    //         description: 'Filler product information-station of generation elation and pereceptual dialation, rooted in straight up syncapation...and stuff.',
    //         images: ['https://res.cloudinary.com/dzxzdsnha/image/upload/v1714336713/Shirt-MedMan_Porthole-Front-Angled-Left_rksqqa.png'], 
    //         price:'$35.00',
    //         details: ['1st detail','Yet another detail','& once more a detail'],
    //         tags: ['tshirt','unisex','uniqueQR']
    //     },
    //     {
    //         _id:'0',
    //         name:'The Gridwalker', 
    //         subtitle:'w/ dynamic QR code & customizable name tag', description: 'Filler product information-station of generation elation and pereceptual dialation, rooted in straight up syncapation...and stuff.',images: ['https://res.cloudinary.com/dzxzdsnha/image/upload/v1714338172/Shirt-Gridwalker-Front-Angled-Left_Fit_hewpmn.png'], 
    //         price:'$35.00',
    //         details: ['1st detail','Yet another detail','& once more a detail'],
    //         tags: ['tshirt','unisex','uniqueQR','nameTag']
    //     },
    //     {
    //         _id:'2',
    //         name:'Sage', 
    //         subtitle:'w/ dynamic QR code & customizable name tag', description: 'Filler product information-station of generation elation and pereceptual dialation, rooted in straight up syncapation...and stuff.',images: ['https://res.cloudinary.com/dzxzdsnha/image/upload/v1714337622/Shirt-Sage-Front-Angled-Left_Fit_wcxvpd.png'], 
    //         price:'$35.00',
    //         details:['1st detail','Yet another detail','& once more a detail'],
    //         tags:['tshirt','unisex','uniqueQR','nameTag']
    //     },
       
    // ]
    // const config = {
    //     headerHeight: '25vh',
    //     header_pic: 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1714322177/ShirtHeaderImage-Crop.jpg',
    //     bgYPos:'0',
    //     avatar_pic: 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg',
    //     aboutHeight: '25vh',
    //     tagline: '( par·al·lax·er )',
    //     aboutContent: `<div style="position:relative; padding:0.5rem"> <img style="float: left; width: 1.5rem; height:auto; left: 1rem;" src= 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1698192201/Parallaxr_Distressed_White_BG_kvsoiz.png' /> brand focused on actively seeing and embracing, through multiple perspectives.<br/><br/> e.g., <em>Such as it may appear, and those that may exist.<em/></div>`,
    //     productsHeight: 'fit-content',
    //  "featured_items:":[],
    // "featured_collection":""
    // }