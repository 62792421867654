
export const generateRandomHex = (len)=> {
      // Create an array of hexadecimal characters
      const characters = 'ABCDEF0123456789';
      // Create an empty string to store the hex string
      let hexString = '';
      // Generate 6 random characters and add them to the hex string
      for (let i = 0; i < len; i++) {
      hexString += characters[Math.floor(Math.random() * characters.length)];
      }

      // Return the hex string
      return hexString;
      }

 export const splitKey = (string,sym,rep) =>{
         return string.split(sym).join(rep)
     }

export const itemNameFilter = (item,term) =>{
         return item?.name?.includes(term);
     }

 export const itemFieldFilter = (item,field,term) =>{
         const field_value = item[field?.toString()]
         if (field_value){

             if(item[field]?.includes(term))
                return true
            else return false 
         }
        else return false
     }

export const orderColorCheck = (status) =>{
   switch(status){

     case'draft':
      return 'grey'
      break;
     case'cart':
      return 'orange'
     break;
     case'complete':
      return 'green'
     break;
     case'hold':
      return 'red'
     break;
     case'processing':
      return 'orange'
     break;
     case'printing':
      return 'blue'
     break;
     case'fulfilled':
      return 'green'
     break;
     case'shipped':
      return 'limegreen'
     break;
   }
  }