import React ,{ useEffect, useRef, useState, useContext } from "react";
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import { AppBar, Button,Grid, TextField, Box, Container, Stack, Typography, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import InquirySendModal from './InquirySendModal';
import ConfirmSignupModal from "../../components/ConfirmSignupModal";

import { AppContext } from "../../context/AppProvider";
import * as API from '../../utils/APICalls'


const CustomerShipping = (props) => {
    const [value, setValue] = useState(0);
    const { owner, operating_name, owner_email, campaingName } = props.shop
    // const subView = props.subView
    const [ inquiry, setInquiry ] = useState({})
    const [ showAdd2, setShowAdd2 ] = useState(false)
    

     const { appData,updateAppData, previewQR, setPreviewQR, 
    setSelectedQR, selectedQR, } = useContext(AppContext);

    const { signupData, 
        setStorefrontData,
        storefrontData,
        updateStorefrontData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
        firstName, 
        setFirstName,
        lastName, 
        setLastName,
        email, 
        setEmail,
        phone, 
        setPhone,
        department, 
        setDepartment,
        notes, setNotes, 
        inquiryText, 
        setInquiryText,
        isFollower, tempOrders,
        setIsFollower, cart,
        setCart,order,
        customer, setCustomer,
        shippingInfo, 
        setShippingInfo, 
        subView,
        setSubView
    } = useContext(StorefrontContext);

    const [ actionError, setActionError ] = useState('')
    const [ isSubmitting , setIsSubmitting ] = useState(false)
    const [ open, setOpen ] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true)

        // if( firstName === ''|| lastName === '' ||
        //     email  === '' || phone  === ''){
        const c = shippingInfo
        console.log('Shipping info:', c)
        if( !c?.name || !c?.address || !c?.city || !c?.state || !c?.zip){
                alert('Please complete or re-enter your shipping information')
            setIsSubmitting(false)
            }
        else{

            try{
                const res = await API.updateCart({
                    ...cart,
                    shipping_addresses:[shippingInfo]
                })
                if (res?._id){
                    
                    setCart({
                        ...res
                        // ...cart,
                        // shipping_addresses:[shippingInfo]
                    })
                    
                    updateStorefrontData({ 
                        ...storefrontData,
                        cart
                    })
                    setSubView('billing')
                    updateAppData({
                        ...appData,
                        subView
                    })
                    // console.log('Submit tempOrder',  tempOrder);
                    console.log('storefrontData', storefrontData);
                }
                setOpen(!open)
                setIsSubmitting(false)
            }catch(err){
                setIsSubmitting(false)
                setActionError('Order failed to submit')
                console.log(err)  
            }
        }
  };

    const handleBack = () =>{
        props.setSubView('cart')
    }
     
    

    useEffect(()=>{
        setCart(props.cart)
    },[])
    useEffect(()=>{
      
    },[subView])

  return (
    <Container  sx={{p:1}}>

    <Box component="form" //noValidate 
            // onSubmit={orderView? handleSubmit : handleInquirySubmit } 
            sx={{ mt: 3,p:3,border:'1px solid #000',borderRadius:'10px' }}>
                <Grid container spacing={2}>
        
            <Grid item xs={12} sm={6}>
                <TextField
                autoComplete="full-name"
                name="name"
                required
                fullWidth
                id="name"
                label="name"
                autoFocus
                defaultValue={ shippingInfo['name']?shippingInfo['name']:''}
                onChange={(e)=>setShippingInfo({
                        ...shippingInfo,
                        [e?.target?.name]: e?.target?.value
                    })}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="address"
                defaultValue={ shippingInfo['address']?shippingInfo['address']:''}
                onChange={(e)=>setShippingInfo({
                    ...shippingInfo,
                    [e?.target?.name]: e?.target?.value
                    })}
                />
            </Grid>
        <Grid item xs={12} sm={6}>
            <Typography sx={{cursor:'pointer', textDecoration:'underline'}}
            
            onClick={()=>{
                setShowAdd2(!showAdd2)
            }}>
            {showAdd2 ?'Hide':'Show' }  Address line 2?
            </Typography>
                </Grid>
            {showAdd2 && <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="address2"
                    label="address2"
                    name="address2"
                    autoComplete="address2"
                defaultValue={ shippingInfo['address2']?shippingInfo['address2']:''}

                    onChange={(e)=>setShippingInfo({
                        ...shippingInfo,
                        [e?.target?.name]: e?.target?.value
                    })}
                    // setShippingAdd(e.target.value)}
                    />
                </Grid>}
        <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    autoComplete="city"
                defaultValue={ shippingInfo['city']?shippingInfo['city']:''}

                    onChange={(e)=>setShippingInfo({
                        ...shippingInfo,
                        [e?.target?.name]: e?.target?.value
                    })}
                    />
                </Grid>
        <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="state"
                    label="State"
                    name="state"
                    autoComplete="state"
                defaultValue={ shippingInfo['state']?shippingInfo['state']:''}

                    onChange={(e)=>setShippingInfo({
                        ...shippingInfo,
                        [e?.target?.name]: e?.target?.value
                    })}
                    />
                </Grid>
        <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="zip"
                    label="zip"
                    name="zip"
                    autoComplete="zip"
                defaultValue={ shippingInfo['zip']?shippingInfo['zip']:''}

                    onChange={(e)=>setShippingInfo({
                        ...shippingInfo,
                        [e?.target?.name]: e?.target?.value
                    })}
                    />
                </Grid>
            <Grid item xs={12}>
                <TextField
                required
                fullWidth
                name="phone"
                label="Phone"
                type="phone"
                id="phone"
                autoComplete="phone"
                defaultValue={ shippingInfo['phone']?shippingInfo['phone']:''}

                onChange={(e)=>setShippingInfo({
                        ...shippingInfo,
                        [e?.target?.name]: e?.target?.value
                    })}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                // required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                defaultValue={ shippingInfo['email']?shippingInfo['email']:''}

                onChange={(e)=>setShippingInfo({
                        ...shippingInfo,
                        [e?.target?.name]: e?.target?.value
                    })}
                />
            </Grid>
        

            {/* <Grid item xs={12}>
                <TextField
                // required
                fullWidth
                name="department"
                label="Department"
                type="text"
                id="department"
                autoComplete="department"
                onChange={(e)=>setDepartment(e.target.value)}
                />
            </Grid> */}
            <Grid item xs={12}>
                <TextField
                // required
                fullWidth
                name="notes"
                label="Notes"
                type="text"
                id="notes"
                autoComplete="notes"
                defaultValue={ shippingInfo['notes']?shippingInfo['notes']:''}

                onChange={(e)=>setNotes(e.target.value)}
                />
            </Grid>
            
            
            {/* <Grid item xs={12} sx={{textAlign:'center'}} >
                <FormControlLabel sx={{margin:'auto'}}
                control={<Checkbox value={isFollower} onChange={(e)=>{setIsFollower(!isFollower)}} checked={isFollower} color="primary"/>}
                label="I want to receive information, marketing promotions and updates via email."
                />
            </Grid> */}
            </Grid>

            {subView === 'shipping' &&<Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem'}}>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
                    onClick={handleBack}
                    >
                    Back To Cart
                </Button>
                <React.Fragment>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
                        onClick={ handleSubmit } 
                        disabled={isSubmitting?true:false}
                        >
                        {/* Check{isSubmitting?'ing ':''}out */}
                        Billing
                    </Button>
                    {actionError}
                
                    {(open && storefrontData?.email ) && <ConfirmSignupModal open={open}  order={{...storefrontData,order:[...tempOrder]}} cart={cart} email={email}
                    owner_email={owner_email}
                    operating_name={operating_name}
                    userObj={{firstName, lastName, email, phone, department, inquiryText, isFollower, }}
                    owner={owner}
                    />}
                </React.Fragment>
                
            </Stack>}
        
            <Grid container justifyContent="flex-end">
            <Grid item>
                {/* <Link href="#" variant="body2">
                Already have an account? Sign in
                </Link> */}
            </Grid>
            </Grid>
        </Box>
    </Container>
  )
}

export default CustomerShipping