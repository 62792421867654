import React, { useState,useRef,useContext,useEffect } from "react";
import { AppContext } from "../../context/AppProvider";
import { QRCodeContext } from "../../context/QRCodeProvider";
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import { Box, Button, Container, Input, Modal, 
    Stack, Typography,InputLabel,TextField  } from '@mui/material'
import * as API from '../../utils/APICalls'
import * as helpers from '../../utils/helpers'
import { faLess } from "@fortawesome/free-brands-svg-icons";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const CompileComboPrintfile = (props) => {
    const view = props.view
    const type = props.type
     const {  setModalOpen, defaultQR,
            currentProduct, setBuildStep, buildStep,
            cart,setCart, setShop, shop,storefrontData, 
            updateStorefrontData} = useContext(StorefrontContext);
            
    const { appData,updateAppData, currentAsset, currentShop,
        newAsset ,currentOrder, setCurrentOrder, } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);

 const [qrPin, setQrPin] = useState('');
  const [orderId, setOrderId] = useState('');
  const [itemId, setItemId] = useState('');
  const [fontType, setFontType] = useState('Gilroy');
  const [fontOpen, setFontOpen] = useState(false);
  const [ hasChanged,setHasChanged ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isSaved, setIsSaved ] = useState(false);
  const [ editMode, setEditMode ] = useState(true);
  const [ refresh,setRefresh ] = useState(false);
  const [multiLine ,setMultiLine ] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [qrImage, setQrImage] = useState(null); // State to store the QR image
  const [assetImage, setAssetImage] = useState(null); // State to store the QR image
  const canvasRef = useRef(null);

  const fonts =['Permanent Marker','Gilroy', 'Protest Riot']//,'Sofia']

   const handleFontClose = () => { setFontOpen(false);};
    const handleFontOpen = () => { setFontOpen(true);};
     const handleFontChange = async(e) => {
        setHasChanged(true)
        setIsSaved(false)
        await setFontType(e.target.value)
      
        // console.log('[handleGpChange] selectedPin:',selectedPin)
 
    };

    const sleep = (time) => {
      return new Promise(resolve => setTimeout(resolve, time))
    }
  const downloadImage = async () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
    
    // postPicDetails(image)
  
    const link = document.createElement('a');
    link.href = image;
    link.download = `Parallaxr-${currentProduct?.name}-PRINTFILE.png`;
    link.click();
  };
  

  const saveImage = async () => {
 
    const canvas = canvasRef?.current;
    const image = canvas?.toDataURL('image/png').replace("image/png", "image/octet-stream");
    try{

      postPicDetails(image)
    }catch(err){console.log(err)
      setIsSaving(false)
    }
    
    
  };

  const saveOrder = async (ord) =>{
    try{
      // if(ord?.printFile_public_id !=='' || ord?.printFile_public_id !==null ){
                  
        console.log('[Saved Order (PreSave)]:', ord)
        const order = await API.updateOrder(ord)
        console.log('[Saved Order]:', order)
      
      if(order){
          setCurrentOrder(order)
        // setBuildStep(4)
          updateStorefrontData({
            ...storefrontData,
              currentOrder,
              // buildStep
            })
          }
        // if(currentOrder?._id) { 
          // updateStorefrontData({
          //   ...storefrontData,
          //   buildStep
          //  })}
      // }
      // else{
        //   saveImage()
        //   sleep(5000)
        // }
        setIsSaving(false)
        console.log('[CompileComboPrintfile] save [buildStep]:', buildStep)
        console.log('[Current Order] :', currentOrder)
      }catch(err){
        setIsSaving(false)
        console.log('Unable to save order',err)}
  }

  const previousPicCheck = async (view,pics) => {
    switch(view){
     
      case('build-shirt' || 'process'):
        if ( currentOrder?.printFile_public_id ){
          const a = await API.deleteOrderAsset( currentOrder )
          // setCurrentOrder({
           
          // })
          try{
            const res = await API.updateOrder({
               ...currentOrder,
                printFile: null,
                printFile_public_id:null,

            })
            if (res){
              setCurrentOrder(res)
              updateAppData({
                ...appData,
                currentOrder
              })
              await postPicDetails(pics)
            }
          }catch(err){console.log('Previous Pic error',err)}
        }
      break;
    }
  }
  const postPicDetails = async (pics) => {

        if (typeof pics === undefined) {
            console.log("pics undefined")
          return;
        } 

         try{

            if(currentOrder?.printFile_public_id?.length){
            await  previousPicCheck(view,pics)
          }
             const data = new FormData();
               data.append("file", pics);
               data.append("upload_preset", "TinCan");
               data.append("folder", "ORDERS");
               data.append("cloud_name", "dzxzdsnha");
               fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
                 method: "post",
                 body: data,
               })
                 .then((res) => res.json())
                 .then((data) => {
          

                  if( view === 'build-shirt' ||view === 'process'){
                    let orderObj = {
                      ...currentOrder,
                       printFile: data?.url?.toString(),
                        printFile_public_id: data?.public_id?.toString(),
                    }
                    saveOrder(orderObj)
                    
                    setCurrentOrder({
                      ...currentOrder,
                      printFile: data?.url?.toString(),
                      printFile_public_id: data?.public_id?.toString(),
                    })
                    updateAppData({
                      ...appData,
                          currentOrder
                      })
                    // updateStorefrontData({
                    //   ...storefrontData,
                    //       currentOrder
                    //   })
                      // if(currentOrder?.printFile_public_id?.length){
                        // if(!orderObj?._id){
                          
                        // }
                        // else if(orderObj?._id){
                        //   overlayId(orderObj)
                        // }
                      // }else{
                      //   sleep(5000)
                      //   saveImage()
                      // }
                    
                  }
                  
                  //    console.log('[BuildLSAsset]:',data.url);
                  setHasChanged(false)
                  setIsSaved(true)
                  setEditMode(false)
                  setIsSaving(false)
                  //  setPicLoading(false);
                 })
                 .catch((err) => {
                   console.log('failure to save',err);
                  //  setPicLoading(false);
                 });
         }catch(err){console.log(err)}
           
               
                 
                  setIsSaving(false)
    
      }
  const handleChange = (event) => {
    if (event.target.name === 'qrPin') {
      // setHasChanged(true)
      setQrPin(event.target.value);
    } else if (event.target.name === 'orderId') {
      // setHasChanged(true)
      setOrderId(event.target.value);
    }
    setHasChanged(true)
    setIsSaved(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true)
    // const text = `${qrPin}\n${orderId}`; 
    try{

        // const image = await fetch('https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'); // Replace with path to your image
        // // const image = await 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'; // Replace with path to your image
        // // const image = await fetch('path/to/your/qr_image.png'); // Replace with path to your image
        // const qrImageBlob = await image?.blob();
        // console.log('[handleSubmit] qrImageBlob:',qrImageBlob)
        // setQrImage(URL.createObjectURL(qrImageBlob));
        // setIsSubmitting(false)
        setIsSubmitting(false)
    }catch(err){
        setIsSubmitting(false)
        console.log(err)
    }
  };
  const drawImage = (ctx, imageUrl, x, y, width, height) => {
  const img = new Image();
  img.src = imageUrl;
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    ctx.drawImage(img, x, y, width, height);
  };
};

const drawText = (ctx, text, x, y, maxWidth=840,fontSize = 300, color = 'black') => {
    let currentFontSize = fontSize;
  ctx.font = `900  ${currentFontSize}px ${fontType}`;
   // Measure text width
  let textWidth = ctx.measureText(text).width;
   // Calculate horizontal centering offset
   //   console.log(['text width:', textWidth])
   // Check for overflow and adjust font size if needed
   while (textWidth > maxWidth) {
       currentFontSize -= 2; // Adjust decrement value as needed
       ctx.font = `${currentFontSize}px ${fontType}`;
       textWidth = ctx.measureText(text).width;
    }
    const horizontalCenterOffset = (maxWidth - textWidth) / 2;

  ctx.fillStyle = color;
  ctx.fillText(text, x + horizontalCenterOffset, y);
  ctx.strokeStyle = 'black'
    // ctx.lineWidth = fontOutline(fontType)
    ctx.lineJoin = "round"
    ctx.strokeText(text, x + horizontalCenterOffset, y) 
};
  const overlayId = async (ord) =>{
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.style.letterSpacing = '5px';

    ctx.save(); 
    ctx.restore();

    if( type ==='nametag mod' || type ==='porthole' || type ==='base' ){
         drawText(ctx, ord?._id?.toUpperCase(), 800, 250); 
     }
    else if(type ==='nametag plain'){
        //  ctx.restore();
        ctx.translate(1275, 1650)
        // ctx.translate(400, 4140)
        ctx.rotate(Math.PI/2);
        drawText(ctx, ord?._id?.toUpperCase(), -750, -825, 1000); 
      }
    setIsLoading(false)
    }

    const updateOrder = async (order) =>{
      try{

        const upd_ord = await API.updateOrder(order)
        if(upd_ord){
          setCurrentOrder({
            ...upd_ord
          })
          updateStorefrontData({
            ...currentOrder
          })
        }
      }catch(err){console.log(err)}
    }


useEffect(() => {
  if(type !== 'base' && (!currentOrder?.qr_id || !currentOrder?.qr_pin)){
    setCurrentOrder({
      ...currentOrder,
      qr_id: qrCodeData?._id,
      qr_pin: qrCodeData?.pin,
    })
    updateAppData({
      ...appData,
      currentOrder
    })

  } 

  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  canvas.style.letterSpacing = '5px';


  // Replace with path to your base image
  const baseImage = new Image();
  baseImage.onload = async () => {
    baseImage.crossOrigin = 'Anonymous';
    ctx.drawImage(baseImage, 0, 0, canvas.width, canvas.height);
    ctx.save(); 
    if(!assetImage){
      try{

        let image 
        if(type ==='nametag mod'){
          image = currentOrder?.asset_url; // Replace with path to your image
        }
        else if(type ==='nametag plain'){
          image = currentOrder?.asset_url; // Replace with path to your image
        }
        else if(type ==='porthole'){
          image = currentOrder?.qr_pic; // Replace with path to your image
        }
        setAssetImage(image);
      }catch(err){console.log(err)}
     }
    
    if (assetImage) {
         if(type ==='nametag mod'){
         drawImage(ctx, assetImage, 1076.625 * 3.127, 98.263 * 4.66, 336 * 3.14, 203.452 *3.14);// x-coordinate, y-coordinate, width, height
                // Adjust these values to position and size the image on the canvas
      // ctx.save(); 
         }
         else if(type ==='nametag plain'){
         drawImage(ctx, assetImage, 200, 1540, 336 * 3.14, 203.452 * 3.14);// x-coordinate, y-coordinate, width, height
                // Adjust these values to position and size the image on the canvas
      // ctx.save(); 
         }
         else if(type ==='porthole'){
            drawImage(ctx, assetImage, 2390, 1290, 890 , 890 );// x-coordinate, y-coordinate, width, height
         
         }
    }
    if(!qrImage){
        //  const image = await fetch('https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'); // Replace with path to your image
        // const image = await fetch('path/to/your/qr_image.png'); // Replace with path to your image
        // const qrImageBlob = await image?.blob();
        // console.log('[handleSubmit] qrImageBlob:',qrImageBlob)
        // setQrImage(URL.createObjectURL(qrImageBlob));
        // const image = (view === 'build-shirt')? qrCodeData?.pic_url : assetQR?.pic_url; // Replace with path to your image
        const image = currentOrder?.qr_pic; // Replace with path to your image
        setQrImage(image);
     }
     if( type ==='base' ){
       drawText(ctx, currentOrder?.cart_id?.toUpperCase(), 800, 350); 
     }
     if (qrImage) {
       ctx.save(); 
       if( type ==='nametag mod' || type ==='porthole' || type ==='standard' || type === 'base' ){
        console.log("currentOrder:",currentOrder)
        // console.log("currentOrder?.qr_pin:",currentOrder?.qr_pin)
         drawText(ctx,currentOrder?.cart_id?.toUpperCase(), 800, 350); 
          drawText(ctx, currentOrder?._id?.toUpperCase(), 700, 200,1200); 
        //  drawText(ctx, currentOrder?.cart_id?.toUpperCase(), 800, 350); 
         ctx.translate(400, 4140)
         
         ctx.rotate(-Math.PI/2);
        //  drawText(ctx, qrCodeData?._id?.toUpperCase(),-520, 870,1300); // Adjust X and Y for name placement and size
        //  drawText(ctx, qrCodeData?.pin?.toUpperCase(),150, 100,750); // Adjust X and Y for name placement and size
         drawText(ctx, type === 'base' ? 'N/A' : currentOrder?.qr_id?.toUpperCase(),-520, 870,type === 'base' ? 300 : 1300); // Adjust X and Y for name placement and size
         drawText(ctx, type === 'base' ? 'N/A' : currentOrder?.qr_pin?.toUpperCase(),150, 100,type === 'base' ? 300 : 750); // Adjust X and Y for name placement and size
         
         //  ctx.resetTransform();
         //  ctx.rotate(-Math.PI/2);
         if( type !== 'base'){
           
           drawImage(ctx, qrImage,430, 4145, 410 *1.6, 410 * 1.6);// x-coordinate, y-coordinate, width, height
           // Adjust these values to position and size the image on the canvas
           
          }else if( type === 'base' ){
            
            drawText(ctx,  'N/A' ,-600, 455,type === 'base' ? 500 : 750); // Adjust X and Y for name placement and size
        }
        }
        
        else if(type ==='nametag plain'){
          //  ctx.restore();
          ctx.save(); 
        
          drawText(ctx, currentOrder?.qr_pin?.toUpperCase(), 1050, 525, 750); // Adjust X and Y for name placement and size
          drawText(ctx, currentOrder?.qr_id?.toUpperCase(), 450, 1255, 1300); // Adjust X and Y for name placement and size
          ctx.translate(1275, 1650)
          // ctx.translate(400, 4140)
          ctx.rotate(Math.PI/2);
      
         
          drawText(ctx, currentOrder?.cart_id?.toUpperCase(), -750, -925, 1000); 
          drawText(ctx, currentOrder?._id?.toUpperCase(), -750, -1075, 1000); 
          // drawText(ctx, currentOrder?.cart_id?.toUpperCase(), 2100, 1850, 1000); 
          ctx.resetTransform();
          // ctx.rotate(-Math.PI/2);
          drawImage(ctx, qrImage,270, 500, 410 * 1.4 , 410 * 1.4);// x-coordinate, y-coordinate, width, height
          // Adjust these values to position and size the image on the canvas
      
        }
      ctx.restore();
    }
     // Draw Text
  // const qrPinText = qrPin; // Assuming minimal formatting needed
  const qrPinText = currentOrder?.qr_pin; // Assuming minimal formatting needed
  const orderIdText = orderId;
  
//  if(multiLine){
    //  drawText(ctx, currentOrder?.cart_id?.toUpperCase(), 800, 200); // Adjust X and Y for company placement
//  }
    
  };
  //!Printbase based on item
  if(view === 'build-shirt'){

    baseImage.src = currentProduct?.printBase_url[0];
  }
  else if(view === 'process'){
    // const item = currentShop?.items?.find(item => item?._id === currentOrder?.item?._id)
    console.log('[process printfile', currentOrder?.printBase)
    baseImage.src = currentOrder?.printBase;
  }
  // baseImage.src = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1717531063/PARALLAXR_PRINTFILES/REFS/HELLO_PRINTFILE-REF_nnmy1z.png';
  canvas.oncontextmenu = function() {return false};
}, [qrImage,qrPin,orderId,fontType,multiLine]);

const disableCheck = () =>{
 if (view === 'build-shirt' && !currentOrder?.pic_public_id && hasChanged )
  {return true}
else if(view === 'create-asset' && newAsset?.pic_public_id && !hasChanged)
  {return true}
else if(view === 'edit-asset' && currentAsset?.pic_public_id && !hasChanged)
  {return true}
else{ return false}

}

  useEffect(()=>{

  },[currentOrder,newAsset,currentAsset,hasChanged,isSaved,isSaving])
  return (
    <Box sx={{fontFamily:'Gilroy,sans-serif',display:'flex', marginTop:'1rem',
          maxWidth:'500px', flexDirection:'column', 
          gap:'0.5rem',justifyContent:'center',padding:'2rem'}}>
      <Typography variant="h4"sx={{fontFamily:'Gilroy,sans-serif',fontWeight:'bold'}}>Building Your Print</Typography>
     
  
             {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                <Typography>2 lines</Typography>
                <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{setMultiLine(!multiLine)}} checked={!multiLine} />
                <Typography>1 line</Typography>
            </Stack> */}

          
      {type !== 'nametag plain'&&<canvas ref={canvasRef} width={4500} height={5100} />}
     {type === 'nametag plain' && <canvas ref={canvasRef} width={2550} height={3300} />}
        {isLoading && !canvasRef && <HourglassBottomIcon 
            className="spinner"
            sx={{position:'absolute',left:'50%',top:'50%',transform:' translate(-50%,-50%)'}}/>}
        <Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem', margin:'1rem auto'}}>
            {/* <Button variant='outlined' type="submit" onClick={()=>setRefresh(!refresh)}>Refresh{isSubmitting?'ing':''}</Button> */}
            
               {/* {!isSaved &&  */}
           {/* { !helpers.itemFieldFilter(currentOrder,'itemName',"[ Base ]")&& 
            !helpers.itemFieldFilter(currentOrder,'itemName',"Porthole") &&
            <Button variant='outlined' 
            // disabled={disableCheck()}
            onClick={()=>{
              setEditMode(true)
              setIsSaved(false)
              setBuildStep(2)
            }

            }>Edit Order</Button>
             }  */}

            {/* {!isSaved && hasChanged && !currentOrder?._id && */}
             <Button variant='outlined' 
              disabled={isSaving}
              onClick={(e)=>{
                setIsSaving(true)
                try{
                  e.preventDefault()
                  saveImage()

                }catch(err){console.log(err)}

                }}>Sav{isSaving ?'ing':'e'} File</Button>
                {/* } */}

       
            {/* {!isSaved && hasChanged && currentOrder?._id && <Button variant='outlined' 
              disabled={isSaving}
              onClick={(e)=>{
                try{
                  e.preventDefault()
                  updateOrder(currentOrder)
                  console.log('[NEW COMPLETION CLICK]:',currentOrder)
                }catch(err){console.log(err)}

                }}>Finaliz{isSaving ?'ing':'e'} Order</Button>} */}

           
        </Stack>
    </Box>
  );
};
export default CompileComboPrintfile